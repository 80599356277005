import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import EditorialLayout from '../components/EditorialLayout';
import MarkdownRenderer from '../components/MarkdownRenderer';
import { loadArticleContent, testArticleLoading } from '../utils/markdownLoader';
import { Loader2, AlertTriangle, Bug } from 'lucide-react';

// Empty related articles until we add more content
const relatedArticles: Array<{
  id: string;
  title: string;
  category: string;
  image: string;
}> = [];

interface ArticleMetadata {
  id: string;
  title: string;
  description: string;
  category: string;
  authorId: string;
  author: string;
  authorAvatar?: string;
  publishDate: string;
  readTime: number;
  heroImage: string;
}

const EditorialDetailPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const params = useParams<{ category: string; id: string }>();
  const editorialId = params.id;
  
  const [editorial, setEditorial] = useState<{
    metadata: {
      id: string;
      title: string;
      description: string;
      category: string;
      authorId: string;
      author: string;
      authorAvatar?: string;
      publishDate: string;
      readTime: number;
      heroImage?: string;
      image?: string;
    };
    content: string;
  } | null>(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [debugInfo, setDebugInfo] = useState<{ 
    contentLength: number;
    contentPreview: string;
    contentType: string;
    loadTime: number;
  } | null>(null);

  useEffect(() => {
    const fetchEditorial = async () => {
      if (!editorialId) {
        setError('Editorial ID not provided');
        setLoading(false);
        return;
      }

      const startTime = performance.now();
      try {
        setLoading(true);
        const editorialData = await loadArticleContent(editorialId);
        setEditorial(editorialData);
        
        // Add debugging information
        const contentType = typeof editorialData.content;
        const contentPreview = contentType === 'string' 
          ? editorialData.content.substring(0, 150) + '...' 
          : 'Not a string';
        
        setDebugInfo({
          contentLength: contentType === 'string' ? editorialData.content.length : 0,
          contentPreview,
          contentType,
          loadTime: performance.now() - startTime
        });
        
        setError(null);
      } catch (err) {
        console.error('Failed to load editorial:', err);
        setError('Editorial not found or failed to load');
      } finally {
        setLoading(false);
      }
    };

    fetchEditorial();
  }, [editorialId]);

  // Scroll to top when component mounts or editorialId changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [editorialId]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-ivory-50">
        <div className="text-center">
          <Loader2 className="w-10 h-10 text-gold-600 animate-spin mx-auto mb-4" />
          <p className="text-midnight-800 font-medium">{t('common.loading')}</p>
        </div>
      </div>
    );
  }

  if (error || !editorial) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-ivory-50">
        <div className="text-center max-w-md px-6">
          <h1 className="text-2xl font-serif font-bold text-midnight-900 mb-4">
            {t('common.editorialNotFound')}
          </h1>
          <p className="text-midnight-700 mb-6">
            {error || t('common.editorialNotFoundMessage')}
          </p>
          
          {process.env.NODE_ENV === 'development' && (
            <div className="mt-4 border-t border-gray-200 pt-4">
              <button
                onClick={async () => {
                  if (editorialId) {
                    const result = await testArticleLoading(editorialId);
                    console.log(result);
                    alert('Diagnostic test complete. Check browser console for results.');
                  }
                }}
                className="flex items-center justify-center gap-2 mx-auto px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                <Bug size={16} />
                Run Diagnostic Test
              </button>
              <p className="text-xs text-gray-500 mt-2">
                Dev only: Runs tests to check content loading paths
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
  
  // Only show in development mode
  const showDebugInfo = process.env.NODE_ENV === 'development';
  
  return (
    <EditorialLayout
      title={editorial.metadata.title}
      description={editorial.metadata.description}
      category={editorial.metadata.category}
      canonicalPath={`/${locale}/editorials/${editorial.metadata.category}/${editorial.metadata.id}`}
      authorId={editorial.metadata.authorId}
      author={editorial.metadata.author}
      authorAvatar={editorial.metadata.authorAvatar}
      publishDate={editorial.metadata.publishDate}
      readTime={editorial.metadata.readTime}
      heroImage={editorial.metadata.heroImage || editorial.metadata.image || ''}
      relatedArticles={relatedArticles}
    >
      {showDebugInfo && debugInfo && (
        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-6 text-xs font-mono">
          <h3 className="font-bold mb-2 flex items-center gap-1">
            <AlertTriangle size={14} /> Debug Information
          </h3>
          <ul className="space-y-1">
            <li><strong>Content Length:</strong> {debugInfo.contentLength} characters</li>
            <li><strong>Content Type:</strong> {debugInfo.contentType}</li>
            <li><strong>Load Time:</strong> {debugInfo.loadTime.toFixed(2)}ms</li>
            <li className="pt-2 border-t border-blue-100 mt-2">
              <strong>Preview:</strong> 
              <pre className="mt-1 overflow-x-auto p-2 bg-blue-100 rounded whitespace-pre-wrap">{debugInfo.contentPreview}</pre>
            </li>
          </ul>
        </div>
      )}
      <MarkdownRenderer content={editorial.content} />
    </EditorialLayout>
  );
};

export default EditorialDetailPage; 