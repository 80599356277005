import React, { useState } from 'react';
import { Battery, Zap, Timer, ArrowRight, Heart, Plus, Check } from 'lucide-react';
import { EVehicle } from '../data/vehicles';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../utils/currency';
import { Link } from 'react-router-dom';

interface VehicleCardProps {
  vehicle: EVehicle;
  onCompare: (vehicle: EVehicle) => void;
  isCompared: boolean;
}

const VehicleCard: React.FC<VehicleCardProps> = ({ vehicle, onCompare, isCompared }) => {
  const { t, i18n } = useTranslation();
  const [imageError, setImageError] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  
  const handleImageError = () => setImageError(true);
  
  return (
    <div 
      className="group bg-white border border-ivory-200 overflow-hidden flex flex-col transition-all duration-200 hover:shadow-md"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Image container */}
      <div className="relative aspect-[4/3] overflow-hidden bg-ivory-100">
        <Link to={`/${i18n.language}/vehicles/${vehicle.id}`}>
          <img
            src={imageError ? '/images/vehicle-placeholder.jpg' : vehicle.image}
            alt={vehicle.name}
            onError={handleImageError}
            className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
          />
        </Link>
        
        {/* Favorite button */}
        <button
          onClick={() => setIsFavorite(!isFavorite)}
          className="absolute top-3 right-3 p-2 bg-white/80 backdrop-blur-sm rounded-full shadow-sm hover:bg-white z-10"
          aria-label={isFavorite ? t('vehicles.removeFromFavorites') : t('vehicles.addToFavorites')}
        >
          <Heart 
            size={16} 
            className={`${isFavorite ? 'fill-gold-600 text-gold-600' : 'text-midnight-600'}`} 
          />
        </button>
        
        {/* Vehicle type tag */}
        <div className="absolute bottom-3 left-3 px-2 py-1 text-xs font-medium text-midnight-700 bg-white/80 backdrop-blur-sm">
          {t(`types.${vehicle.type.toLowerCase()}`)}
        </div>
      </div>
      
      {/* Content */}
      <div className="p-4 flex-grow flex flex-col">
        <div className="mb-3">
          <div className="text-sm text-gold-700 font-medium">
            {vehicle.brand}
          </div>
          <Link to={`/${i18n.language}/vehicles/${vehicle.id}`} className="group-hover:text-gold-700">
            <h3 className="font-serif text-lg font-medium text-midnight-900 transition-colors">
              {vehicle.name}
            </h3>
          </Link>
          <div className="mt-1 text-sm text-midnight-600">
            {formatCurrency(vehicle.price, i18n.language)}
          </div>
        </div>
        
        {/* Specs */}
        <div className="grid grid-cols-3 gap-2 mt-auto">
          <div className="flex flex-col items-center p-2 bg-ivory-50">
            <Battery size={16} className="text-midnight-500 mb-1" />
            <span className="text-xs text-midnight-700 text-center font-medium">
              {vehicle.batteryCapacity} kWh
            </span>
          </div>
          <div className="flex flex-col items-center p-2 bg-ivory-50">
            <Zap size={16} className="text-midnight-500 mb-1" />
            <span className="text-xs text-midnight-700 text-center font-medium">
              {vehicle.range} km
            </span>
          </div>
          <div className="flex flex-col items-center p-2 bg-ivory-50">
            <Timer size={16} className="text-midnight-500 mb-1" />
            <span className="text-xs text-midnight-700 text-center font-medium">
              {vehicle.acceleration}s
            </span>
          </div>
        </div>
      </div>
      
      {/* Footer */}
      <div className="flex border-t border-ivory-200">
        <button
          onClick={() => onCompare(vehicle)}
          className={`flex items-center justify-center gap-1 py-2 flex-1 text-sm font-medium transition-colors ${
            isCompared 
              ? 'bg-midnight-800 text-white hover:bg-midnight-700' 
              : 'bg-white text-midnight-700 hover:bg-ivory-100'
          }`}
        >
          {isCompared ? <Check size={16} /> : <Plus size={16} />}
          {isCompared ? t('vehicles.compared') : t('vehicles.compare')}
        </button>
        
        <Link
          to={`/${i18n.language}/vehicles/${vehicle.id}`}
          className="flex items-center justify-center gap-1 py-2 px-3 bg-ivory-50 text-midnight-900 text-sm font-medium hover:bg-gold-50 hover:text-gold-800 transition-colors"
        >
          {t('vehicles.details')}
          <ArrowRight size={14} className={`transition-transform duration-300 ${isHovered ? 'translate-x-1' : ''}`} />
        </Link>
      </div>
    </div>
  );
};

export default VehicleCard;
