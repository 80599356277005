import editorialData from '../data/editorials.json';
import { Editorial } from '../types/editorial';

export interface ArticleMetadata {
  id: string;
  title: string;
  description: string;
  category: string;
  authorId: string;
  author: string;
  authorAvatar?: string;
  publishDate: string;
  readTime: number;
  heroImage: string;
  image?: string;
}

/**
 * In a real application, this function would load markdown files from the server
 * or from a CMS. For this demo, we're loading from static files.
 */
export const loadArticleContent = async (articleId: string): Promise<{ metadata: ArticleMetadata; content: string }> => {
  try {
    // Find the article metadata in our JSON data
    const article = (editorialData as Editorial[]).find(item => item.id === articleId);
    
    if (!article) {
      throw new Error(`Article with ID "${articleId}" not found in editorial data`);
    }
    
    let content = '';
    
    try {
      console.log(`Attempting to load markdown file for: ${articleId}`);
      
      // Get the current site URL (handles both Cloudflare and local development)
      const baseUrl = window.location.origin;
      
      // Cloudflare-optimized paths - these reflect how Cloudflare Pages organizes static files
      const paths = [
        // Primary paths for Cloudflare Pages
        `/content/articles/${articleId}.md`,
        `/articles/${articleId}.md`,
        
        // Fallback paths for various deployment scenarios
        `${baseUrl}/content/articles/${articleId}.md`,
        `/public/content/articles/${articleId}.md`,
        `/src/content/articles/${articleId}.md`
      ];
      
      // Log for debugging
      console.log('Attempting to load from the following paths:', paths);
      
      let contentLoaded = false;
      
      // Try each path
      for (const path of paths) {
        try {
          console.log(`Trying to fetch markdown from: ${path}`);
          const response = await fetch(path, {
            // This helps with Cloudflare caching - indicates we're okay with cached content
            headers: {
              'Cache-Control': 'public, max-age=3600'
            }
          });
          
          if (response.ok) {
            content = await response.text();
            contentLoaded = true;
            console.log(`Successfully loaded markdown from: ${path}`);
            break;
          } else {
            console.log(`Failed to load from ${path}: ${response.status} ${response.statusText}`);
          }
        } catch (e) {
          console.warn(`Error trying path ${path}:`, e);
          // Continue to next path
        }
      }
      
      // If no paths worked but we're in development, use hardcoded content for testing
      if (!contentLoaded && process.env.NODE_ENV === 'development' && 
          articleId === 'tesla-cybertruck-availability-in-thailand-predictions') {
        content = `# The Cybertruck in Thailand: A Reality Check for Tesla's Most Polarizing Vehicle

Having spent considerable time analyzing automotive trends across Southeast Asia and following Tesla's global strategy closely, I've been fascinated by the speculation surrounding the Cybertruck's potential arrival in Thailand. The whispers intensified after Tesla's surprising Cybertruck tour through Bangkok earlier this year, but let me be clear: Thailand isn't getting the Cybertruck anytime soon, and there are compelling reasons why.

## Tesla's North American Fortress 

First, let's acknowledge the reality of Tesla's current strategy. Elon Musk and company have made their position crystal clear: the Cybertruck is a North American vehicle. Period. The official stance remains that this angular beast is exclusively destined for the United States, Canada, and Mexico. Having followed Tesla's production roadmaps for years, I can confidently say this isn't merely a temporary position – it's a deliberate strategy based on manufacturing capacity and market prioritization.

Why? The answer is painfully obvious to anyone watching Tesla's quarterly delivery figures. The company is still struggling to ramp production at Giga Texas to meet overwhelming domestic demand. During my visit to the Austin facility earlier this year, the production challenges were evident despite the impressive scale of operations. The Cybertruck's unorthodox manufacturing requirements – from the stainless steel exoskeleton to the massive casting machines – make scaling production especially challenging.

## Thailand's Pickup Market: Size Matters

I've spent considerable time in Thailand's automotive landscape, and while it's true that pickups dominate the market, they're a fundamentally different breed than the Cybertruck. The Toyota Hilux and Isuzu D-Max that rule Thailand's roads are compact, practical workhorses built for tight urban streets and rural functionality.

The Cybertruck, by contrast, is comically oversized for Thai infrastructure. During its Bangkok tour, the vehicle's dimensions drew incredulous reactions from onlookers. Having personally attempted to navigate Bangkok's congested streets in vehicles far smaller than the Cybertruck, I can tell you with absolute certainty that daily driving this behemoth would range from impractical to impossible in most Thai contexts.`;
        console.log('Using hardcoded content for the Cybertruck article');
        contentLoaded = true;
      }
      
      if (!contentLoaded) {
        throw new Error(`Failed to load article content from any path for ID: ${articleId}`);
      }
    } catch (error) {
      console.error('Error loading markdown:', error);
      
      // Fallback to a sample placeholder content if we can't load the markdown
      content = `# ${article.title}

${article.description}

*This content is temporarily unavailable. Please check back later.*`;
    }
    
    // Return the structured result
    return {
      metadata: {
        id: article.id,
        title: article.title,
        description: article.description,
        category: article.category,
        authorId: article.authorId,
        author: article.author,
        authorAvatar: article.authorAvatar,
        publishDate: article.publishDate,
        readTime: article.readTime || 10,
        heroImage: article.image
      },
      content
    };
  } catch (error) {
    console.error('Error loading article:', error);
    throw error;
  }
};

// Add a diagnostic function to help with debugging
export const testArticleLoading = async (articleId: string) => {
  console.group('Article Loading Diagnostic Test');
  console.log(`Testing article loading for ID: ${articleId}`);
  
  // Log the article data from JSON
  const article = (editorialData as Editorial[]).find(item => item.id === articleId);
  console.log('Article metadata from JSON:', article);
  
  // Test all possible paths
  const baseUrl = window.location.origin;
  const paths = [
    `${baseUrl}/src/content/articles/${articleId}.md`,
    `${baseUrl}/content/articles/${articleId}.md`,
    `${baseUrl}/${articleId}.md`,
    `${baseUrl}/public/content/articles/${articleId}.md`,
    `/src/content/articles/${articleId}.md`,
    `/content/articles/${articleId}.md`,
    `./src/content/articles/${articleId}.md`
  ];
  
  console.log('Testing the following paths:');
  
  for (const path of paths) {
    try {
      console.log(`Trying path: ${path}`);
      const response = await fetch(path);
      const status = response.status;
      const ok = response.ok;
      
      console.log(`Status: ${status}, OK: ${ok}`);
      
      if (ok) {
        const content = await response.text();
        console.log(`Content loaded successfully! First 100 chars: ${content.substring(0, 100)}...`);
      }
    } catch (error) {
      console.error(`Error with path ${path}:`, error);
    }
  }
  
  console.groupEnd();
  return 'Diagnostic test complete - check console for results';
}; 