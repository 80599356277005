import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeHighlight from 'rehype-highlight';
import 'prismjs/themes/prism-tomorrow.css';
import { LinkIcon, ExternalLink, AlertTriangle } from 'lucide-react';

interface MarkdownRendererProps {
  content: string;
}

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ content }) => {
  const [safeContent, setSafeContent] = useState<string>('');
  const [renderError, setRenderError] = useState<boolean>(false);
  
  useEffect(() => {
    // Debug the incoming content
    console.log('MarkdownRenderer received content:', 
      content ? `${content.substring(0, 100)}... (${content.length} chars)` : 'empty content');
    
    if (!content || content.trim() === '') {
      // Handle empty content
      setRenderError(true);
      setSafeContent('# Content Not Available\n\nThe article content could not be loaded.');
      return;
    }
    
    // Quick validation to check if content looks like HTML
    // This can happen if raw HTML is accidentally displayed instead of rendered
    const looksLikeHTML = content.trim().startsWith('<') && (
      content.includes('<!DOCTYPE') || 
      content.includes('<html') || 
      content.includes('<head') || 
      content.includes('<meta') ||
      content.includes('<script') ||
      content.includes('<body')
    );
    
    if (looksLikeHTML) {
      // If it looks like HTML, set an error and provide a clean fallback
      console.error('Content appears to be HTML instead of markdown:', content.substring(0, 200));
      setRenderError(true);
      setSafeContent('# Content Error\n\nThe article content could not be properly rendered.');
    } else {
      // Content looks fine, use it
      setRenderError(false);
      setSafeContent(content);
    }
  }, [content]);

  // Custom renderers for markdown components
  const components = {
    h1: ({ node, ...props }: any) => (
      <h1 className="font-serif text-3xl font-bold text-midnight-950 my-6" {...props} />
    ),
    h2: ({ node, ...props }: any) => (
      <h2 className="font-serif text-2xl font-bold text-midnight-950 mt-10 mb-4" {...props} />
    ),
    h3: ({ node, ...props }: any) => (
      <h3 className="font-serif text-xl font-bold text-midnight-950 mt-8 mb-4" {...props} />
    ),
    h4: ({ node, ...props }: any) => (
      <h4 className="font-serif text-lg font-bold text-midnight-950 mt-6 mb-3" {...props} />
    ),
    p: ({ node, ...props }: any) => (
      <p className="my-4 text-midnight-800 leading-relaxed" {...props} />
    ),
    a: ({ node, href, ...props }: any) => {
      const isExternal = href?.startsWith('http');
      return (
        <a 
          href={href}
          className="text-gold-700 hover:text-gold-900 border-b border-gold-300 pb-0.5 transition-colors inline-flex items-center gap-1"
          target={isExternal ? "_blank" : undefined}
          rel={isExternal ? "noopener noreferrer" : undefined}
          {...props}
        >
          {props.children}
          {isExternal && <ExternalLink size={14} />}
        </a>
      );
    },
    ul: ({ node, ...props }: any) => (
      <ul className="list-disc pl-6 my-4 text-midnight-800 space-y-2" {...props} />
    ),
    ol: ({ node, ...props }: any) => (
      <ol className="list-decimal pl-6 my-4 text-midnight-800 space-y-2" {...props} />
    ),
    li: ({ node, ...props }: any) => (
      <li className="leading-relaxed" {...props} />
    ),
    blockquote: ({ node, ...props }: any) => (
      <blockquote className="border-l-4 border-gold-400 pl-4 py-1 my-6 text-midnight-700 italic" {...props} />
    ),
    code: ({ node, inline, className, children, ...props }: any) => {
      const match = /language-(\w+)/.exec(className || '');
      return !inline ? (
        <div className="my-6 overflow-hidden">
          <code
            className={match ? `language-${match[1]} block bg-midnight-950 text-ivory-100 p-4 overflow-x-auto rounded-none text-sm` : 'bg-midnight-950 text-ivory-100 p-4 block overflow-x-auto rounded-none text-sm'}
            {...props}
          >
            {children}
          </code>
        </div>
      ) : (
        <code className="bg-midnight-100 text-midnight-800 px-1.5 py-0.5 text-sm font-mono" {...props}>
          {children}
        </code>
      );
    },
    pre: ({ node, ...props }: any) => (
      <pre className="bg-transparent border-none p-0 m-0" {...props} />
    ),
    img: ({ node, src, alt, ...props }: any) => (
      <figure className="my-8">
        <img
          src={src}
          alt={alt || ''}
          className="w-full h-auto"
          loading="lazy"
          {...props}
        />
        {alt && <figcaption className="text-center text-sm text-midnight-600 mt-2 italic">{alt}</figcaption>}
      </figure>
    ),
    hr: ({ node, ...props }: any) => (
      <hr className="my-8 border-t border-ivory-300" {...props} />
    ),
    table: ({ node, ...props }: any) => (
      <div className="overflow-x-auto my-8">
        <table className="w-full text-left border-collapse" {...props} />
      </div>
    ),
    th: ({ node, ...props }: any) => (
      <th className="border-b-2 border-midnight-200 bg-ivory-100 font-medium p-3 text-midnight-900" {...props} />
    ),
    td: ({ node, ...props }: any) => (
      <td className="border-b border-midnight-100 p-3 text-midnight-800" {...props} />
    ),
  };

  if (renderError) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-6 my-8">
        <div className="flex items-center gap-2 text-red-600 mb-4">
          <AlertTriangle size={20} />
          <h3 className="font-bold">Error Rendering Content</h3>
        </div>
        <p className="text-red-800">
          There was a problem rendering this article's content. Our team has been notified and is working to fix it.
        </p>
      </div>
    );
  }

  return (
    <ReactMarkdown 
      remarkPlugins={[remarkGfm]} 
      rehypePlugins={[rehypeRaw, rehypeHighlight]}
      components={components}
    >
      {safeContent}
    </ReactMarkdown>
  );
};

export default MarkdownRenderer; 