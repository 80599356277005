import './i18n';
import React, { useState, useMemo, Suspense, useCallback, useEffect } from 'react';
import { Menu } from 'lucide-react';
import { vehicles, EVehicle } from './data/vehicles';
import VehicleCard from './components/VehicleCard';
import ComparisonPanel from './components/ComparisonPanel';
import Filters from './components/Filters';
import LanguageSwitch from './components/LanguageSwitch';
import { useTranslation } from 'react-i18next';
import { Routes, Route, Navigate, useLocation, useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { SEOHead } from './components/SEOHead';
import emailjs from '@emailjs/browser';
import { Locale } from './i18n/seoConfig';
import CompareBrands from './pages/CompareBrands';
import Sorry from './pages/Sorry';
import { Layout } from './components/Layout';
import ElectricCarsThailand from './pages/ElectricCarsThailand';
import BrandPage from './pages/BrandPage';
import NotFound from './pages/NotFound';
import { GoogleAnalytics } from './components/GoogleAnalytics';
import VehicleTypePage from './pages/VehicleTypePage';
import LuxuryHeader from './components/LuxuryHeader';
import LuxuryFooter from './components/LuxuryFooter';
import LuxuryHomePage from './pages/LuxuryHomePage';
import CommunityPage from './pages/CommunityPage';
import EditorialDetailPage from './pages/EditorialDetailPage';
import EditorialCategoryListing from './pages/EditorialCategoryListing';

// Lazy load components
const Experience = React.lazy(() => import('./pages/Experience'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Quiz = React.lazy(() => import('./pages/Quiz'));
const VehicleDetail = React.lazy(() => import('./pages/VehicleDetail'));
const VehiclesPage = React.lazy(() => import('./pages/VehiclesPage'));

// Initialize EmailJS
emailjs.init("lUupjW33Ww3-N0WR7");

// Home component remains the same
const Home: React.FC<{
  filteredVehicles: EVehicle[];
  comparedVehicles: EVehicle[];
  handleCompare: (vehicle: EVehicle) => void;
  setComparedVehicles: React.Dispatch<React.SetStateAction<EVehicle[]>>;
}> = ({ filteredVehicles, comparedVehicles, handleCompare, setComparedVehicles }) => {
  const { t } = useTranslation();
  
  return (
    <main className="container mx-auto px-4 py-6 lg:py-8 max-w-7xl">
      <div className="mb-8">
        <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-2">
          {t('home.title')}
        </h1>
        <p className="text-sm md:text-base text-gray-600 max-w-2xl">
          {t('home.subtitle')}
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6">
        {filteredVehicles.map((vehicle) => (
          <VehicleCard
            key={vehicle.id}
            vehicle={vehicle}
            onCompare={handleCompare}
            isCompared={comparedVehicles.some(v => v.id === vehicle.id)}
          />
        ))}
      </div>
      {comparedVehicles.length > 0 && (
        <div className="fixed bottom-0 left-0 right-0 z-50 bg-white shadow-lg border-t border-gray-200">
          <ComparisonPanel 
            vehicles={comparedVehicles} 
            onRemove={(id) => {
              setComparedVehicles(prev => prev.filter(v => v.id !== id));
            }} 
          />
        </div>
      )}
    </main>
  );
};

const AppContent: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const locale = i18n.language as Locale;

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 10000000]);
  const [batteryRange, setBatteryRange] = useState<[number, number]>([0, 200]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState('price-asc');
  const [comparedVehicles, setComparedVehicles] = useState<EVehicle[]>([]);
  const [selectedMakes, setSelectedMakes] = useState<string[]>([]);

  // Add this effect to handle language changes
  useEffect(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const langInPath = pathSegments[0];
    
    if (langInPath !== 'th' && langInPath !== 'en') {
      // No language in path, redirect to default language
      const preferredLanguage = localStorage.getItem('i18nextLng') || 'en';
      const newPath = location.pathname === '/' ? `/${preferredLanguage}` : `/${preferredLanguage}${location.pathname}`;
      navigate(`${newPath}${location.search}`, { replace: true });
    } else if (langInPath !== i18n.language) {
      // Update i18n language to match URL
      i18n.changeLanguage(langInPath);
    }
  }, [location.pathname, i18n, navigate]);

  // Memoize the filtered vehicles calculation
  const filteredVehicles = useMemo(() => {
    const searchLower = searchQuery.toLowerCase().trim();
    return vehicles.filter((vehicle) => {
      const matchesSearch = !searchLower || 
        vehicle.name.toLowerCase().includes(searchLower) ||
        vehicle.brand.toLowerCase().includes(searchLower);
      const matchesPrice =
        vehicle.price >= priceRange[0] && vehicle.price <= priceRange[1];
      const matchesBattery =
        vehicle.batteryCapacity >= batteryRange[0] &&
        vehicle.batteryCapacity <= batteryRange[1];
      const matchesType =
        selectedTypes.length === 0 || selectedTypes.includes(vehicle.type);
      const matchesMake =
        selectedMakes.length === 0 || selectedMakes.includes(vehicle.brand);

      return matchesSearch && matchesPrice && matchesBattery && matchesType && matchesMake;
    }).sort((a, b) => {
      switch (sortBy) {
        case 'price-asc':
          return a.price - b.price;
        case 'price-desc':
          return b.price - a.price;
        case 'range-desc':
          return b.range - a.range;
        case 'acceleration-asc':
          return a.acceleration - b.acceleration;
        default:
          return 0;
      }
    });
  }, [searchQuery, priceRange, batteryRange, selectedTypes, selectedMakes, sortBy]);

  // Remove the memoized HomeWithState and make it a regular component
  const HomeWithState = () => (
    <div>
      <Filters
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        priceRange={priceRange}
        setPriceRange={setPriceRange}
        batteryRange={batteryRange}
        setBatteryRange={setBatteryRange}
        selectedTypes={selectedTypes}
        setSelectedTypes={setSelectedTypes}
        selectedMakes={selectedMakes}
        setSelectedMakes={setSelectedMakes}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
      <Home
        filteredVehicles={filteredVehicles}
        comparedVehicles={comparedVehicles}
        handleCompare={handleCompare}
        setComparedVehicles={setComparedVehicles}
      />
    </div>
  );

  // Function to get SEO data based on current route
  const getSeoData = () => {
    const searchParams = new URLSearchParams(location.search);
    const searchTerm = searchParams.get('search');
    const pathWithoutLang = location.pathname.replace(/^\/(en|th)/, '');

    // Common paths that should have canonical URLs
    const commonPaths: Record<string, { title: string; description: string }> = {
      '/vehicles/type/sedan': {
        title: t('vehicleTypes.sedan.title'),
        description: t('vehicleTypes.sedan.description'),
      },
      '/vehicles/type/hatchback': {
        title: t('vehicleTypes.hatchback.title'),
        description: t('vehicleTypes.hatchback.description'),
      },
      '/compare-brands/neta-vs-tesla': {
        title: t('comparisons.netaVsTesla.title'),
        description: t('comparisons.netaVsTesla.description'),
      },
      '/compare-brands/aion-vs-ora': {
        title: t('comparisons.aionVsOra.title'),
        description: t('comparisons.aionVsOra.description'),
      },
      '/compare-brands/aion-vs-hyundai': {
        title: t('comparisons.aionVsHyundai.title'),
        description: t('comparisons.aionVsHyundai.description'),
      },
      '/compare-brands/volvo-vs-nissan': {
        title: t('comparisons.volvoVsNissan.title'),
        description: t('comparisons.volvoVsNissan.description'),
      },
    };

    if (Object.keys(commonPaths).includes(pathWithoutLang)) {
      return commonPaths[pathWithoutLang];
    }

    return {
      title: t('home.title'),
      description: t('home.subtitle'),
    };
  };

  // Use a memoized function to handle the compare button click
  const handleCompare = useCallback((vehicle: EVehicle) => {
    setComparedVehicles(prev => {
      const isAlreadyCompared = prev.some(v => v.id === vehicle.id);
      
      if (isAlreadyCompared) {
        return prev.filter(v => v.id !== vehicle.id);
      } else {
        // Limit to max 3 vehicles for comparison
        if (prev.length < 3) {
          return [...prev, vehicle];
        }
        return prev;
      }
    });
  }, []);

  const seoData = useMemo(() => getSeoData(), [location.pathname, t]);

  // Keep only the BrandRedirectHandler for brand comparisons
  const BrandRedirectHandler = () => {
    const { brands } = useParams();
    const { i18n } = useTranslation();
    const locale = i18n.language as Locale;
    
    const transformedBrands = brands?.replace(/-vs-/g, '/');
    return <Navigate to={`/${locale}/compare-brands/${transformedBrands}`} replace />;
  };

  return (
    <>
      <SEOHead
        title={seoData.title}
        description={seoData.description}
        canonicalPath={location.pathname}
        locale={locale}
      />
      <GoogleAnalytics />
      
      <Routes>
        {/* Redirect root to language-specific root */}
        <Route path="/" element={<Navigate to={`/${locale}`} replace />} />
        
        {/* All application routes */}
        <Route element={<Layout />}>
          {/* Luxury Home (New Default) */}
          <Route path="/:locale" element={<LuxuryHomePage />} />
          
          {/* Directory Routes */}
          <Route path="/:locale/vehicles" element={
            <Suspense fallback={<div>Loading...</div>}>
              <VehiclesPage />
            </Suspense>
          } />
          
          <Route path="/:locale/vehicles/:id" element={
            <Suspense fallback={<div>Loading...</div>}>
              <VehicleDetail />
            </Suspense>
          } />
          
          <Route path="/:locale/vehicles/type/:type" element={<VehicleTypePage />} />
          
          <Route path="/:locale/compare-brands" element={<CompareBrands />} />
          
          <Route path="/:locale/compare-brands/:brands" element={<CompareBrands />} />
          
          <Route path="/:locale/brand/:brand" element={<BrandPage />} />
          
          {/* Content-Driven Routes */}
          <Route path="/:locale/editorials" element={<EditorialCategoryListing />} />
          
          <Route path="/:locale/editorials/reviews" element={<EditorialCategoryListing />} />
          <Route path="/:locale/editorials/features" element={<EditorialCategoryListing />} />
          <Route path="/:locale/editorials/news" element={<EditorialCategoryListing />} />
          <Route path="/:locale/editorials/guides" element={<EditorialCategoryListing />} />
          <Route path="/:locale/editorials/comparison" element={<EditorialCategoryListing />} />
          
          <Route path="/:locale/editorials/:category/:id" element={<EditorialDetailPage />} />
          
          <Route path="/:locale/community" element={<CommunityPage />} />
          <Route path="/:locale/community/:section" element={<CommunityPage />} />
          
          {/* Contact & Experience Routes */}
          <Route path="/:locale/contact" element={
            <Suspense fallback={<div>Loading...</div>}>
              <Contact />
            </Suspense>
          } />
          
          <Route path="/:locale/experience" element={
            <Suspense fallback={<div>Loading...</div>}>
              <Experience />
            </Suspense>
          } />
          
          <Route path="/:locale/quiz" element={
            <Suspense fallback={<div>Loading...</div>}>
              <Quiz />
            </Suspense>
          } />
          
          {/* Legacy redirects */}
          <Route path="/compare/:brands" element={<BrandRedirectHandler />} />
          <Route path="/:locale/compare/:brands" element={<BrandRedirectHandler />} />
          
          {/* 404 Page */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default function App() {
  return (
    <HelmetProvider>
      <AppContent />
    </HelmetProvider>
  );
}
