import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOHeadProps {
  title: string;
  description: string;
  canonicalPath: string;
  locale: string;
  breadcrumbs?: Array<{ label: string; path: string }>;
  category?: string;
  authorId?: string;
  author?: string;
  authorAvatar?: string;
  publishDate?: string;
  readTime?: number;
  heroImage?: string;
}

export const SEOHead: React.FC<SEOHeadProps> = ({
  title,
  description,
  canonicalPath,
  locale,
  breadcrumbs,
  category,
  author,
  publishDate,
  heroImage
}) => {
  // Remove language prefix from canonical path if it exists
  const pathWithoutLang = canonicalPath.replace(/^\/(en|th)/, '');
  const normalizedPath = pathWithoutLang.startsWith('/') ? pathWithoutLang : `/${pathWithoutLang}`;
  
  // Base canonical URL without language prefix
  const baseCanonicalUrl = `https://www.rodfaifa.com${normalizedPath}`.replace(/\/$/, '');
  
  // Language-specific canonical URL
  const canonicalUrl = locale === 'en' 
    ? `https://www.rodfaifa.com/en${normalizedPath}`.replace(/\/$/, '')
    : `https://www.rodfaifa.com/th${normalizedPath}`.replace(/\/$/, '');

  // Determine if this is an article page
  const isArticle = !!category && !!publishDate;
  
  // Default image for social sharing
  const defaultImage = 'https://www.rodfaifa.com/rodfaifa-logo.png';
  const ogImage = heroImage || defaultImage;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={ogImage} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta property="og:locale" content={locale === 'en' ? 'en_US' : 'th_TH'} />
      <meta property="og:site_name" content="RODFAIFA" />
      <meta property="og:type" content={isArticle ? 'article' : 'website'} />
      
      {/* Article specific structured data */}
      {isArticle && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            "headline": title,
            "description": description,
            "image": heroImage || defaultImage,
            "author": {
              "@type": "Person",
              "name": author
            },
            "publisher": {
              "@type": "Organization",
              "name": "RODFAIFA",
              "logo": {
                "@type": "ImageObject",
                "url": "https://www.rodfaifa.com/rodfaifa-logo.png"
              }
            },
            "datePublished": publishDate,
            "dateModified": publishDate
          })}
        </script>
      )}
      
      {/* Breadcrumbs structured data */}
      {breadcrumbs && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": breadcrumbs.map((item, index) => ({
              "@type": "ListItem",
              "position": index + 1,
              "item": {
                "@id": `https://www.rodfaifa.com${item.path}`,
                "name": item.label
              }
            }))
          })}
        </script>
      )}
      
      {/* Organization structured data */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "RODFAIFA",
          "url": "https://www.rodfaifa.com",
          "logo": "https://www.rodfaifa.com/rodfaifa-logo.png",
          "sameAs": [
            "https://facebook.com/rodfaifa",
            "https://twitter.com/rodfaifa",
            "https://instagram.com/rodfaifa"
          ]
        })}
      </script>
      
      <link rel="alternate" href={baseCanonicalUrl} hrefLang="x-default" />
      <link rel="alternate" href={`https://www.rodfaifa.com/en${normalizedPath}`} hrefLang="en" />
      <link rel="alternate" href={`https://www.rodfaifa.com/th${normalizedPath}`} hrefLang="th" />
    </Helmet>
  );
};
