import authorsData from '../data/authors.json';
import { Author } from '../types/editorial';

/**
 * Get author information by ID
 * @param authorId The ID of the author to retrieve
 * @returns Author information or undefined if not found
 */
export const getAuthorById = (authorId: string): Author | undefined => {
  return (authorsData as Author[]).find(author => author.id === authorId);
};

/**
 * Get author bio based on author ID and current language
 * @param authorId The ID of the author
 * @param language The current language (en or th)
 * @returns The author's bio in the specified language or a fallback message
 */
export const getAuthorBio = (authorId: string, language: string = 'en'): string => {
  const author = getAuthorById(authorId);
  
  if (!author) {
    return 'Author information not available.';
  }
  
  // Use requested language or fallback to English
  return author.bio[language as keyof typeof author.bio] || author.bio.en;
};

/**
 * Get formatted author information including name and role
 * @param authorId The ID of the author
 * @returns Formatted string with author name and role or just the name if author not found
 */
export const getAuthorInfo = (authorId: string, authorName: string): {name: string, role: string} => {
  const author = getAuthorById(authorId);
  
  if (!author) {
    return {
      name: authorName,
      role: ''
    };
  }
  
  return {
    name: author.name,
    role: author.role
  };
}; 