import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { SEOHead } from '../components/SEOHead';
import { ArrowRight } from 'lucide-react';
import editorialData from '../data/editorials.json';
import { Editorial } from '../types/editorial';

// Helper function to parse dates for sorting
const parsePublishDate = (dateStr: string) => {
  const months = {
    'January': 0, 'February': 1, 'March': 2, 'April': 3,
    'May': 4, 'June': 5, 'July': 6, 'August': 7,
    'September': 8, 'October': 9, 'November': 10, 'December': 11
  };
  
  const parts = dateStr.split(' ');
  const month = months[parts[0] as keyof typeof months];
  const day = parseInt(parts[1].replace(',', ''));
  const year = parseInt(parts[2]);
  
  return new Date(year, month, day);
};

// Sort editorials by date (newest first)
const sortedEditorials = [...editorialData as Editorial[]].sort((a, b) => {
  return parsePublishDate(b.publishDate).getTime() - parsePublishDate(a.publishDate).getTime();
});

// Use sorted editorial data
const allEditorials = sortedEditorials;

const EditorialCategoryListing: React.FC = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const { category } = useParams<{ category?: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Local state for featured filter
  const [showFeaturedOnly, setShowFeaturedOnly] = useState<boolean>(false);
  const [activeCategory, setActiveCategory] = useState<string | null>(category || null);
  
  // Sync URL parameter with component state
  useEffect(() => {
    // Extract category from path
    const pathParts = location.pathname.split('/');
    const categoryIndex = pathParts.findIndex(part => part === 'editorials') + 1;
    const urlCategory = pathParts[categoryIndex];
    
    if (urlCategory && ['reviews', 'features', 'news', 'guides', 'comparison'].includes(urlCategory)) {
      setActiveCategory(urlCategory);
    } else if (pathParts[categoryIndex-1] === 'editorials' && !urlCategory) {
      setActiveCategory(null);
    }
  }, [location.pathname]);
  
  // Filter editorials based on selected category and featured status
  const filteredEditorials = React.useMemo(() => {
    let filtered = [...allEditorials];
    
    if (activeCategory) {
      filtered = filtered.filter(editorial => editorial.category === activeCategory);
    }
    
    if (showFeaturedOnly) {
      filtered = filtered.filter(editorial => editorial.featured);
    }
    
    return filtered;
  }, [activeCategory, showFeaturedOnly]);

  // Group editorials for the main page display
  const featuredEditorials = !activeCategory 
    ? allEditorials.filter(editorial => editorial.featured)
    : [];

  const otherEditorials = !activeCategory && featuredEditorials.length > 0
    ? allEditorials.filter(editorial => !editorial.featured)
    : [];

  const title = activeCategory 
    ? t(`editorials.categories.${activeCategory}.title`) 
    : t('editorials.title');
    
  const description = activeCategory 
    ? t(`editorials.categories.${activeCategory}.description`) 
    : t('editorials.description');

  const canonicalPath = activeCategory 
    ? `/${locale}/editorials/${activeCategory}` 
    : `/${locale}/editorials`;
    
  // Client-side category navigation
  const handleCategoryChange = (newCategory: string | null) => {
    if (newCategory) {
      navigate(`/${locale}/editorials/${newCategory}`);
    } else {
      navigate(`/${locale}/editorials`);
    }
    setActiveCategory(newCategory);
  };

  return (
    <>
      <SEOHead
        title={title}
        description={description}
        canonicalPath={canonicalPath}
        locale={locale}
      />
      
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-midnight-950 to-midnight-900 text-ivory-50 py-16 md:py-20">
        <div className="container mx-auto px-6">
          <div className="max-w-3xl">
            <h1 className="font-serif text-4xl md:text-5xl font-bold mb-6 leading-tight">
              {activeCategory ? t(`header.${activeCategory}`) : t('common.editorials')}
            </h1>
            <div className="h-1 w-32 bg-gradient-to-r from-gold-500 to-gold-300 mb-8"></div>
            <p className="text-lg md:text-xl text-ivory-100 mb-8 max-w-2xl">
              {description}
            </p>
          </div>
        </div>
      </section>
      
      <div className="bg-ivory-50 py-12">
        <div className="container mx-auto px-6">
          {/* Category Filter */}
          <div className="flex flex-wrap gap-3 mb-6 relative">
            <button
              onClick={() => handleCategoryChange(null)}
              className={`block px-4 py-2 rounded-md text-sm font-medium transition-colors cursor-pointer ${
                !activeCategory ? 'bg-gold-500 text-midnight-950' : 'bg-midnight-100 text-midnight-700 hover:bg-midnight-200'
              }`}
            >
              {t('common.all')}
            </button>
            <button
              onClick={() => handleCategoryChange('reviews')}
              className={`block px-4 py-2 rounded-md text-sm font-medium transition-colors cursor-pointer ${
                activeCategory === 'reviews' ? 'bg-gold-500 text-midnight-950' : 'bg-midnight-100 text-midnight-700 hover:bg-midnight-200'
              }`}
            >
              {t('header.reviews')}
            </button>
            <button
              onClick={() => handleCategoryChange('features')}
              className={`block px-4 py-2 rounded-md text-sm font-medium transition-colors cursor-pointer ${
                activeCategory === 'features' ? 'bg-gold-500 text-midnight-950' : 'bg-midnight-100 text-midnight-700 hover:bg-midnight-200'
              }`}
            >
              {t('header.features')}
            </button>
            <button
              onClick={() => handleCategoryChange('news')}
              className={`block px-4 py-2 rounded-md text-sm font-medium transition-colors cursor-pointer ${
                activeCategory === 'news' ? 'bg-gold-500 text-midnight-950' : 'bg-midnight-100 text-midnight-700 hover:bg-midnight-200'
              }`}
            >
              {t('header.news')}
            </button>
            <button
              onClick={() => handleCategoryChange('guides')}
              className={`block px-4 py-2 rounded-md text-sm font-medium transition-colors cursor-pointer ${
                activeCategory === 'guides' ? 'bg-gold-500 text-midnight-950' : 'bg-midnight-100 text-midnight-700 hover:bg-midnight-200'
              }`}
            >
              {t('header.guides')}
            </button>
            <button
              onClick={() => handleCategoryChange('comparison')}
              className={`block px-4 py-2 rounded-md text-sm font-medium transition-colors cursor-pointer ${
                activeCategory === 'comparison' ? 'bg-gold-500 text-midnight-950' : 'bg-midnight-100 text-midnight-700 hover:bg-midnight-200'
              }`}
            >
              {t('header.comparison')}
            </button>
          </div>
          
          {/* Featured Filter */}
          <div className="flex items-center mb-10">
            <button
              onClick={() => setShowFeaturedOnly(!showFeaturedOnly)}
              className={`flex items-center gap-2 px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                showFeaturedOnly ? 'bg-gold-500 text-midnight-950' : 'bg-midnight-100 text-midnight-700 hover:bg-midnight-200'
              }`}
            >
              <span className={`w-4 h-4 border border-current rounded-sm flex items-center justify-center ${showFeaturedOnly ? 'bg-midnight-950' : 'bg-transparent'}`}>
                {showFeaturedOnly && (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="w-3 h-3 text-gold-500">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                )}
              </span>
              Featured Editorials Only
            </button>
          </div>
          
          {/* Editorials Grid */}
          {!activeCategory && !showFeaturedOnly && featuredEditorials.length > 0 ? (
            <>
              <div className="mb-10">
                <h2 className="font-serif text-2xl font-bold text-midnight-950 mb-6">
                  {t('editorials.featuredEditorials')}
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  {featuredEditorials.slice(0, 2).map((editorial) => (
                    <Link
                      key={editorial.id}
                      to={`/${locale}/editorials/${editorial.category}/${editorial.id}`}
                      className="group block"
                    >
                      <article className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 h-full flex flex-col">
                        <div className="relative h-56 overflow-hidden">
                          <img
                            src={editorial.image}
                            alt={editorial.title}
                            className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
                          />
                          <div className="absolute top-4 left-4 bg-gold-500 text-midnight-950 px-3 py-1 text-sm font-medium">
                            {t(`luxuryHome.categories.${editorial.category}`)}
                          </div>
                        </div>
                        <div className="p-6 flex flex-col flex-grow">
                          <h2 className="font-serif text-xl font-bold text-midnight-950 mb-3 group-hover:text-gold-700 transition-colors">
                            {editorial.title}
                          </h2>
                          <p className="text-midnight-700 mb-5 line-clamp-3">
                            {editorial.description}
                          </p>
                          <div className="mt-auto flex items-center justify-between text-sm">
                            <div className="text-midnight-600">
                              {editorial.author}
                            </div>
                            <div className="text-midnight-500">
                              {editorial.publishDate}
                            </div>
                          </div>
                          <div className="mt-4 text-gold-700 group-hover:text-gold-800 flex items-center text-sm font-medium">
                            {t('editorial.readMore')}
                            <ArrowRight size={16} className="ml-1" />
                          </div>
                        </div>
                      </article>
                    </Link>
                  ))}
                </div>
              </div>
              
              <div>
                <h2 className="font-serif text-2xl font-bold text-midnight-950 mb-6">
                  {t('editorials.latestEditorials')}
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {otherEditorials.map((editorial) => (
                    <Link
                      key={editorial.id}
                      to={`/${locale}/editorials/${editorial.category}/${editorial.id}`}
                      className="group block"
                    >
                      <article className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 h-full flex flex-col">
                        <div className="relative h-56 overflow-hidden">
                          <img
                            src={editorial.image}
                            alt={editorial.title}
                            className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
                          />
                          <div className="absolute top-4 left-4 bg-gold-500 text-midnight-950 px-3 py-1 text-sm font-medium">
                            {t(`luxuryHome.categories.${editorial.category}`)}
                          </div>
                        </div>
                        <div className="p-6 flex flex-col flex-grow">
                          <h2 className="font-serif text-xl font-bold text-midnight-950 mb-3 group-hover:text-gold-700 transition-colors">
                            {editorial.title}
                          </h2>
                          <p className="text-midnight-700 mb-5 line-clamp-3">
                            {editorial.description}
                          </p>
                          <div className="mt-auto flex items-center justify-between text-sm">
                            <div className="text-midnight-600">
                              {editorial.author}
                            </div>
                            <div className="text-midnight-500">
                              {editorial.publishDate}
                            </div>
                          </div>
                          <div className="mt-4 text-gold-700 group-hover:text-gold-800 flex items-center text-sm font-medium">
                            {t('editorial.readMore')}
                            <ArrowRight size={16} className="ml-1" />
                          </div>
                        </div>
                      </article>
                    </Link>
                  ))}
                </div>
              </div>
            </>
          ) : filteredEditorials.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {filteredEditorials.map((editorial) => (
                <Link
                  key={editorial.id}
                  to={`/${locale}/editorials/${editorial.category}/${editorial.id}`}
                  className="group block"
                >
                  <article className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 h-full flex flex-col">
                    <div className="relative h-56 overflow-hidden">
                      <img
                        src={editorial.image}
                        alt={editorial.title}
                        className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
                      />
                      <div className="absolute top-4 left-4 bg-gold-500 text-midnight-950 px-3 py-1 text-sm font-medium">
                        {t(`luxuryHome.categories.${editorial.category}`)}
                      </div>
                    </div>
                    <div className="p-6 flex flex-col flex-grow">
                      <h2 className="font-serif text-xl font-bold text-midnight-950 mb-3 group-hover:text-gold-700 transition-colors">
                        {editorial.title}
                      </h2>
                      <p className="text-midnight-700 mb-5 line-clamp-3">
                        {editorial.description}
                      </p>
                      <div className="mt-auto flex items-center justify-between text-sm">
                        <div className="text-midnight-600">
                          {editorial.author}
                        </div>
                        <div className="text-midnight-500">
                          {editorial.publishDate}
                        </div>
                      </div>
                      <div className="mt-4 text-gold-700 group-hover:text-gold-800 flex items-center text-sm font-medium">
                        {t('editorial.readMore')}
                        <ArrowRight size={16} className="ml-1" />
                      </div>
                    </div>
                  </article>
                </Link>
              ))}
            </div>
          ) : (
            <div className="text-center py-10">
              <p className="text-lg text-midnight-700">
                {t('editorials.noEditorialsFound')}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditorialCategoryListing; 