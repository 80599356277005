import React from 'react';
import LuxuryHeader from './LuxuryHeader';
import LuxuryFooter from './LuxuryFooter';
import { vehicles } from '../data/vehicles';
import { Outlet } from 'react-router-dom';

export const Layout: React.FC = () => {
  return (
    <>
      <LuxuryHeader vehicleCount={vehicles.length} />
      <Outlet />
      <LuxuryFooter />
    </>
  );
};