import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EVehicle } from '../data/vehicles';
import { ComparisonTable } from './ComparisonTable';
import { RelatedComparisons } from './RelatedComparisons';
import { SEOHead } from './SEOHead';
import type { Locale } from '../i18n/seoConfig';
import { BanknoteIcon, ArrowLeft, ChevronDown, ChevronUp } from 'lucide-react';
import { Link } from 'react-router-dom';

interface BrandComparisonContentProps {
  brand1: string;
  brand2: string;
  brand1Vehicles: EVehicle[];
  brand2Vehicles: EVehicle[];
}

export const BrandComparisonContent: React.FC<BrandComparisonContentProps> = ({
  brand1,
  brand2,
  brand1Vehicles,
  brand2Vehicles
}) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language as Locale;
  const [activeSections, setActiveSections] = useState({
    specifications: true,
    keyDifferences: true,
    faq: true
  });

  const toggleSection = (section: keyof typeof activeSections) => {
    setActiveSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const getPriceRange = (vehicles: EVehicle[]) => {
    const prices = vehicles.map(v => v.price);
    return {
      min: Math.min(...prices).toLocaleString(),
      max: Math.max(...prices).toLocaleString()
    };
  };

  const brand1Prices = getPriceRange(brand1Vehicles);
  const brand2Prices = getPriceRange(brand2Vehicles);

  const getMaxRange = (vehicles: EVehicle[]) => {
    return Math.max(...vehicles.map(v => v.range));
  };

  const getMinAcceleration = (vehicles: EVehicle[]) => {
    return Math.min(...vehicles.map(v => v.acceleration));
  };
  
  const getAvgBatteryCapacity = (vehicles: EVehicle[]) => {
    const sum = vehicles.reduce((acc, v) => acc + v.batteryCapacity, 0);
    return (sum / vehicles.length).toFixed(1);
  };
  
  const compareStats = [
    {
      name: "Models",
      value1: brand1Vehicles.length,
      value2: brand2Vehicles.length
    },
    {
      name: "Starting Price",
      value1: `฿${brand1Prices.min}`,
      value2: `฿${brand2Prices.min}`
    },
    {
      name: "Maximum Range",
      value1: `${getMaxRange(brand1Vehicles)} km`,
      value2: `${getMaxRange(brand2Vehicles)} km`
    },
    {
      name: "Best Acceleration",
      value1: `${getMinAcceleration(brand1Vehicles)}s`,
      value2: `${getMinAcceleration(brand2Vehicles)}s`
    },
    {
      name: "Average Battery",
      value1: `${getAvgBatteryCapacity(brand1Vehicles)} kWh`,
      value2: `${getAvgBatteryCapacity(brand2Vehicles)} kWh`
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <SEOHead
        title={`${brand1} vs ${brand2} Comparison`}
        description={`Compare ${brand1} and ${brand2} electric vehicles - specs, prices, and features`}
        canonicalPath={`/compare-brands/${brand1.toLowerCase()}-vs-${brand2.toLowerCase()}`}
        locale={locale}
      />

      <article className="max-w-5xl mx-auto">
        {/* Back link */}
        <div className="mb-6">
          <Link 
            to={`/${locale}/compare-brands`}
            className="inline-flex items-center text-gray-700 hover:text-gray-900 transition-colors"
          >
            <ArrowLeft size={16} className="mr-2" />
            Back to All Brands
          </Link>
        </div>
        
        {/* Hero Section */}
        <div className="bg-midnight-950 rounded-lg p-8 mb-12 text-white">
          <h1 className="text-4xl font-serif font-bold mb-4">
            {brand1} <span className="text-gray-400">vs</span> {brand2}
          </h1>
          <div className="h-1 w-32 bg-gray-400 mb-6"></div>
          <p className="text-lg text-gray-300 max-w-3xl">
            Compare specifications, prices, and features of all available models
          </p>
        </div>

        {/* Quick Stats Comparison */}
        <section className="mb-12">
          <div className="grid grid-cols-1 lg:grid-cols-7 gap-6">
            <div className="lg:col-span-1 bg-gray-100 rounded-lg p-4 flex flex-col justify-center items-center">
              <div className="text-sm uppercase tracking-wider text-gray-500 mb-1">Criteria</div>
            </div>
            <div className="lg:col-span-3 bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
              <div className="bg-gray-100 px-6 py-4 border-b">
                <h2 className="text-2xl font-serif font-bold text-gray-900">{brand1}</h2>
              </div>
            </div>
            <div className="lg:col-span-3 bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
              <div className="bg-gray-100 px-6 py-4 border-b">
                <h2 className="text-2xl font-serif font-bold text-gray-900">{brand2}</h2>
              </div>
            </div>
          </div>
          
          {compareStats.map((stat, idx) => (
            <div key={idx} className="grid grid-cols-1 lg:grid-cols-7 gap-6 mt-3">
              <div className="lg:col-span-1 bg-gray-100 rounded-lg p-4 flex items-center">
                <span className="font-medium text-gray-800">{stat.name}</span>
              </div>
              <div className="lg:col-span-3 bg-white rounded-lg shadow-sm border border-gray-200 p-4">
                <span className="text-xl font-semibold text-gray-900">{stat.value1}</span>
              </div>
              <div className="lg:col-span-3 bg-white rounded-lg shadow-sm border border-gray-200 p-4">
                <span className="text-xl font-semibold text-gray-900">{stat.value2}</span>
              </div>
            </div>
          ))}
        </section>

        {/* Model Comparison */}
        <section className="mb-12 bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
          <div 
            className="bg-gray-100 px-6 py-4 border-b flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection('specifications')}
          >
            <h2 className="text-2xl font-serif font-bold text-gray-900">Detailed Comparison</h2>
            {activeSections.specifications ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </div>
          {activeSections.specifications && (
            <div className="p-6 overflow-x-auto">
              <ComparisonTable 
                brand1Vehicles={brand1Vehicles}
                brand2Vehicles={brand2Vehicles}
              />
            </div>
          )}
        </section>

        {/* Key Differences */}
        <section className="mb-12 bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
          <div 
            className="bg-gray-100 px-6 py-4 border-b flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection('keyDifferences')}
          >
            <h2 className="text-2xl font-serif font-bold text-gray-900">Key Differences</h2>
            {activeSections.keyDifferences ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </div>
          {activeSections.keyDifferences && (
            <div className="p-6 prose max-w-none">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
                  <h3 className="text-xl font-serif font-semibold mb-4 text-gray-800">
                    {brand1} Advantages
                  </h3>
                  <ul className="space-y-3 text-gray-700">
                    {getMinAcceleration(brand1Vehicles) < getMinAcceleration(brand2Vehicles) && (
                      <li className="flex items-start">
                        <span className="mr-2 mt-1 text-gray-600">•</span>
                        Faster acceleration at {getMinAcceleration(brand1Vehicles)}s
                      </li>
                    )}
                    {getMaxRange(brand1Vehicles) > getMaxRange(brand2Vehicles) && (
                      <li className="flex items-start">
                        <span className="mr-2 mt-1 text-gray-600">•</span>
                        Longer range at {getMaxRange(brand1Vehicles)} km
                      </li>
                    )}
                    {parseInt(brand1Prices.min.replace(/,/g, '')) < parseInt(brand2Prices.min.replace(/,/g, '')) && (
                      <li className="flex items-start">
                        <span className="mr-2 mt-1 text-gray-600">•</span>
                        More affordable starting at ฿{brand1Prices.min}
                      </li>
                    )}
                  </ul>
                </div>
                
                <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
                  <h3 className="text-xl font-serif font-semibold mb-4 text-gray-800">
                    {brand2} Advantages
                  </h3>
                  <ul className="space-y-3 text-gray-700">
                    {getMinAcceleration(brand2Vehicles) < getMinAcceleration(brand1Vehicles) && (
                      <li className="flex items-start">
                        <span className="mr-2 mt-1 text-gray-600">•</span>
                        Faster acceleration at {getMinAcceleration(brand2Vehicles)}s
                      </li>
                    )}
                    {getMaxRange(brand2Vehicles) > getMaxRange(brand1Vehicles) && (
                      <li className="flex items-start">
                        <span className="mr-2 mt-1 text-gray-600">•</span>
                        Longer range at {getMaxRange(brand2Vehicles)} km
                      </li>
                    )}
                    {parseInt(brand2Prices.min.replace(/,/g, '')) < parseInt(brand1Prices.min.replace(/,/g, '')) && (
                      <li className="flex items-start">
                        <span className="mr-2 mt-1 text-gray-600">•</span>
                        More affordable starting at ฿{brand2Prices.min}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              
              <div className="mt-10">
                <h3 className="text-xl font-serif font-semibold mb-4">Price Comparison</h3>
                <p className="mb-6 text-gray-700">
                  {brand1} vehicles are priced between ฿{brand1Prices.min} - ฿{brand1Prices.max}, while {brand2} models range from ฿{brand2Prices.min} - ฿{brand2Prices.max}. The difference in starting price is ฿{Math.abs(parseInt(brand1Prices.min.replace(/,/g, '')) - parseInt(brand2Prices.min.replace(/,/g, ''))).toLocaleString()}.
                </p>
                
                <h3 className="text-xl font-serif font-semibold mb-4">Range Comparison</h3>
                <p className="mb-6 text-gray-700">
                  The maximum range for {brand1} is {getMaxRange(brand1Vehicles)} km, while {brand2}'s furthest-traveling model can go up to {getMaxRange(brand2Vehicles)} km. That's a difference of {Math.abs(getMaxRange(brand1Vehicles) - getMaxRange(brand2Vehicles))} km.
                </p>
              </div>
            </div>
          )}
        </section>

        {/* FAQ Section */}
        <section className="mb-12 bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
          <div 
            className="bg-gray-100 px-6 py-4 border-b flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection('faq')}
          >
            <h2 className="text-2xl font-serif font-bold text-gray-900">Frequently Asked Questions</h2>
            {activeSections.faq ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </div>
          {activeSections.faq && (
            <div className="p-6">
              <div className="space-y-6">
                <div className="border-b border-gray-200 pb-6">
                  <h3 className="text-xl font-serif font-semibold mb-2">
                    Which is better, {brand1} or {brand2}?
                  </h3>
                  <p className="text-gray-700">
                    Both {brand1} and {brand2} offer compelling options. {brand1} models range from ฿{brand1Prices.min} to ฿{brand1Prices.max}, while {brand2} vehicles are priced from ฿{brand2Prices.min} to ฿{brand2Prices.max}. Your choice should depend on your specific needs, including range, performance, and features.
                  </p>
                </div>
                <div className="border-b border-gray-200 pb-6">
                  <h3 className="text-xl font-serif font-semibold mb-2">
                    What is the price difference between {brand1} and {brand2}?
                  </h3>
                  <p className="text-gray-700">
                    {brand1}'s lineup starts at ฿{brand1Prices.min} and goes up to ฿{brand1Prices.max}, while {brand2}'s models begin at ฿{brand2Prices.min} and reach ฿{brand2Prices.max}. The starting price difference is ฿{Math.abs(parseInt(brand1Prices.min.replace(/,/g, '')) - parseInt(brand2Prices.min.replace(/,/g, ''))).toLocaleString()}.
                  </p>
                </div>
              </div>
            </div>
          )}
        </section>

        {/* Related Comparisons */}
        <section className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
          <div className="bg-gray-100 px-6 py-4 border-b">
            <h2 className="text-2xl font-serif font-bold text-gray-900">Related Comparisons</h2>
          </div>
          <div className="p-6">
            <RelatedComparisons 
              currentBrand1={brand1}
              currentBrand2={brand2}
            />
          </div>
        </section>
      </article>
    </div>
  );
};