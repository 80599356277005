import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Facebook, Instagram, Twitter, Mail } from 'lucide-react';

const LuxuryFooter: React.FC = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const currentYear = new Date().getFullYear();
  
  // Function to scroll to top when a link is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="bg-white border-t border-ivory-200 text-midnight-800">
      {/* Newsletter signup */}
      <div className="border-b border-ivory-200">
        <div className="container mx-auto px-6 py-10 text-center">
          <h3 className="font-serif text-xl font-medium text-midnight-900 mb-3">
            {t('luxuryHome.newsletter.heading')}
          </h3>
          <p className="text-midnight-600 mb-6 max-w-xl mx-auto text-sm">
            {t('luxuryHome.newsletter.subheading')}
          </p>
          <form className="flex flex-col sm:flex-row gap-3 max-w-md mx-auto">
            <input 
              type="email" 
              placeholder={t('luxuryHome.newsletter.placeholder')}
              className="flex-grow px-4 py-2 rounded-none border border-midnight-200 text-midnight-900 focus:outline-none focus:ring-1 focus:ring-gold-500 focus:border-gold-500"
            />
            <button 
              type="submit" 
              className="px-6 py-2 bg-midnight-900 hover:bg-midnight-800 text-white font-medium transition-colors"
            >
              {t('luxuryHome.newsletter.button')}
            </button>
          </form>
        </div>
      </div>
      
      {/* Main footer content */}
      <div className="container mx-auto px-6 py-10">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Column 1: Logo & About */}
          <div className="md:col-span-1">
            <Link to={`/${locale}/`} onClick={scrollToTop} className="inline-block mb-6">
              <img 
                src="/rodfaifa-type-logo.png" 
                alt="RODFAIFA Logo" 
                className="h-10 w-auto" 
              />
            </Link>
            <p className="text-midnight-600 text-sm mb-6 leading-relaxed">
              {t('footer.aboutShort')}
            </p>
          </div>

          {/* Footer navigation columns */}
          <div className="md:col-span-3 grid grid-cols-1 sm:grid-cols-3 gap-8">
            {/* Column 2: Editorial */}
            <div>
              <h3 className="text-midnight-900 font-medium text-sm uppercase tracking-wider mb-4">
                {t('footer.editorial')}
              </h3>
              <ul className="space-y-3">
                <li>
                  <Link to={`/${locale}/editorials/reviews`} onClick={scrollToTop} className="text-midnight-700 hover:text-gold-700 text-sm">
                    {t('footer.reviews')}
                  </Link>
                </li>
                <li>
                  <Link to={`/${locale}/editorials/features`} onClick={scrollToTop} className="text-midnight-700 hover:text-gold-700 text-sm">
                    {t('footer.features')}
                  </Link>
                </li>
                <li>
                  <Link to={`/${locale}/editorials/news`} onClick={scrollToTop} className="text-midnight-700 hover:text-gold-700 text-sm">
                    {t('footer.news')}
                  </Link>
                </li>
                <li>
                  <Link to={`/${locale}/editorials/guides`} onClick={scrollToTop} className="text-midnight-700 hover:text-gold-700 text-sm">
                    {t('footer.guides')}
                  </Link>
                </li>
              </ul>
            </div>

            {/* Column 3: Directory */}
            <div>
              <h3 className="text-midnight-900 font-medium text-sm uppercase tracking-wider mb-4">
                {t('footer.directory')}
              </h3>
              <ul className="space-y-3">
                <li>
                  <Link to={`/${locale}/vehicles`} onClick={scrollToTop} className="text-midnight-700 hover:text-gold-700 text-sm">
                    {t('footer.allVehicles')}
                  </Link>
                </li>
                <li>
                  <Link to={`/${locale}/vehicles/type/sedan`} onClick={scrollToTop} className="text-midnight-700 hover:text-gold-700 text-sm">
                    {t('footer.sedans')}
                  </Link>
                </li>
                <li>
                  <Link to={`/${locale}/vehicles/type/suv`} onClick={scrollToTop} className="text-midnight-700 hover:text-gold-700 text-sm">
                    {t('footer.suvs')}
                  </Link>
                </li>
                <li>
                  <Link to={`/${locale}/compare-brands`} onClick={scrollToTop} className="text-midnight-700 hover:text-gold-700 text-sm">
                    {t('footer.compareBrands')}
                  </Link>
                </li>
              </ul>
            </div>

            {/* Column 4: Community & Legal */}
            <div>
              <h3 className="text-midnight-900 font-medium text-sm uppercase tracking-wider mb-4">
                {t('footer.community')}
              </h3>
              <ul className="space-y-3">
                <li>
                  <Link to={`/${locale}/community`} onClick={scrollToTop} className="text-midnight-700 hover:text-gold-700 text-sm">
                    {t('footer.forum')}
                  </Link>
                </li>
                <li>
                  <Link to={`/${locale}/community/events`} onClick={scrollToTop} className="text-midnight-700 hover:text-gold-700 text-sm">
                    {t('footer.events')}
                  </Link>
                </li>
                <li>
                  <Link to={`/${locale}/contact`} onClick={scrollToTop} className="text-midnight-700 hover:text-gold-700 text-sm">
                    {t('footer.contact')}
                  </Link>
                </li>
              </ul>
              
              <h3 className="text-midnight-900 font-medium text-sm uppercase tracking-wider mt-8 mb-4">
                {t('footer.legal')}
              </h3>
              <ul className="space-y-3">
                <li>
                  <Link to={`/${locale}/privacy`} onClick={scrollToTop} className="text-midnight-700 hover:text-gold-700 text-sm">
                    {t('footer.privacy')}
                  </Link>
                </li>
                <li>
                  <Link to={`/${locale}/terms`} onClick={scrollToTop} className="text-midnight-700 hover:text-gold-700 text-sm">
                    {t('footer.terms')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        {/* Bottom section with social and copyright */}
        <div className="mt-12 pt-8 border-t border-ivory-200 flex flex-col md:flex-row justify-between items-center">
          {/* Social icons */}
          <div className="flex space-x-6 mb-6 md:mb-0">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-midnight-600 hover:text-gold-700">
              <Facebook size={18} />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-midnight-600 hover:text-gold-700">
              <Instagram size={18} />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-midnight-600 hover:text-gold-700">
              <Twitter size={18} />
            </a>
            <a href="mailto:contact@rodfaifa.com" className="text-midnight-600 hover:text-gold-700">
              <Mail size={18} />
            </a>
          </div>
          
          {/* Copyright text */}
          <p className="text-midnight-500 text-sm">
            © {currentYear} RODFAIFA. {t('footer.rightsReserved')}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default LuxuryFooter; 