import React from 'react';
import { useTranslation } from 'react-i18next';
import { SEOHead } from '../components/SEOHead';

const CommunityPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  
  return (
    <>
      <SEOHead
        title={t('community.pageTitle')}
        description={t('community.pageDescription')}
        canonicalPath={`/${locale}/community`}
        locale={locale}
      />
      
      <main className="min-h-screen bg-ivory-50">
        {/* Coming Soon Section */}
        <section className="bg-midnight-950 text-ivory-50 py-24 md:py-32 min-h-[60vh] flex items-center">
          <div className="container mx-auto px-6 text-center">
            <div className="mx-auto max-w-2xl">
              <h1 className="font-serif text-4xl md:text-5xl lg:text-6xl font-bold mb-6 leading-tight">
                {t('community.hero.title')}
              </h1>
              <div className="bg-gold-500 h-1 w-24 mx-auto mb-8"></div>
              <p className="text-xl text-ivory-200 mb-8">
                Coming Soon
              </p>
              <p className="text-lg text-ivory-300 max-w-xl mx-auto">
                We're working hard to build an amazing community platform for EV enthusiasts in Thailand. Stay tuned for updates!
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default CommunityPage; 