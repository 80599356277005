import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Search, Menu, X, ChevronDown } from 'lucide-react';
import LanguageSwitch from './LanguageSwitch';

interface LuxuryHeaderProps {
  vehicleCount?: number;
}

const LuxuryHeader: React.FC<LuxuryHeaderProps> = ({ vehicleCount = 0 }) => {
  const { t, i18n } = useTranslation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const locale = i18n.language;
  const navigate = useNavigate();

  // Handle navigation with React Router
  const handleNavigate = (path: string) => {
    navigate(path);
    setMobileMenuOpen(false); // Close mobile menu when navigating
  };

  return (
    <header className="relative bg-white border-b border-ivory-200 text-midnight-950">
      {/* Top bar with language info */}
      <div className="hidden lg:block border-b border-ivory-200">
        <div className="container mx-auto px-6 py-2 flex justify-end items-center">
          <div className="flex items-center gap-4 text-sm">
            <LanguageSwitch />
          </div>
        </div>
      </div>
      
      {/* Main navigation - HODINKEE style with centered logo */}
      <div className="container mx-auto px-6 py-5">
        <div className="flex items-center justify-between lg:hidden">
          <button 
            className="text-midnight-950"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            {mobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
          
          <Link to={`/${locale}/`} className="flex items-center">
            <img 
              src="/rodfaifa-type-logo.png" 
              alt="RODFAIFA Logo" 
              className="h-9 w-auto" 
            />
          </Link>
          
          <button
            className="text-midnight-950"
            onClick={() => setSearchOpen(!searchOpen)}
          >
            <Search size={20} />
          </button>
        </div>
        
        {/* Desktop Navigation - HODINKEE style */}
        <div className="hidden lg:flex flex-col items-center">
          {/* Centered Logo */}
          <Link to={`/${locale}/`} className="mb-6">
            <img 
              src="/rodfaifa-type-logo.png" 
              alt="RODFAIFA Logo" 
              className="h-12 w-auto" 
            />
          </Link>
          
          {/* Navigation Links Below Logo */}
          <nav className="flex items-center justify-center space-x-10">
            <div className="group relative">
              <button 
                className="flex items-center gap-1 text-midnight-900 hover:text-gold-700 transition-colors text-sm uppercase tracking-wider font-medium"
                aria-expanded="false"
                aria-haspopup="true"
              >
                {t('header.editorial')}
                <ChevronDown size={14} className="opacity-70 group-hover:text-gold-700" />
              </button>
              <div className="absolute left-0 mt-2 w-56 rounded-none border border-ivory-200 shadow-md bg-white opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50">
                <div className="py-2">
                  <Link 
                    to={`/${locale}/editorials`}
                    className="block w-full text-left px-4 py-2.5 text-sm text-midnight-900 hover:bg-ivory-100 hover:text-gold-700 transition-colors"
                  >
                    {t('common.all')} {t('common.editorials')}
                  </Link>
                  <div className="h-px w-full bg-ivory-200 my-1"></div>
                  <Link 
                    to={`/${locale}/editorials/reviews`}
                    className="block w-full text-left px-4 py-2.5 text-sm text-midnight-900 hover:bg-ivory-100 hover:text-gold-700 transition-colors"
                  >
                    {t('header.reviews')}
                  </Link>
                  <Link 
                    to={`/${locale}/editorials/features`}
                    className="block w-full text-left px-4 py-2.5 text-sm text-midnight-900 hover:bg-ivory-100 hover:text-gold-700 transition-colors"
                  >
                    {t('header.features')}
                  </Link>
                  <Link 
                    to={`/${locale}/editorials/news`}
                    className="block w-full text-left px-4 py-2.5 text-sm text-midnight-900 hover:bg-ivory-100 hover:text-gold-700 transition-colors"
                  >
                    {t('header.news')}
                  </Link>
                  <Link 
                    to={`/${locale}/editorials/guides`}
                    className="block w-full text-left px-4 py-2.5 text-sm text-midnight-900 hover:bg-ivory-100 hover:text-gold-700 transition-colors"
                  >
                    {t('header.guides')}
                  </Link>
                  <Link 
                    to={`/${locale}/editorials/comparison`}
                    className="block w-full text-left px-4 py-2.5 text-sm text-midnight-900 hover:bg-ivory-100 hover:text-gold-700 transition-colors"
                  >
                    {t('header.comparison')}
                  </Link>
                </div>
              </div>
            </div>
            
            <Link 
              to={`/${locale}/`}
              className="text-midnight-900 hover:text-gold-700 transition-colors text-sm uppercase tracking-wider font-medium"
            >
              {t('header.home')}
            </Link>
            
            <Link 
              to={`/${locale}/vehicles`}
              className="text-midnight-900 hover:text-gold-700 transition-colors text-sm uppercase tracking-wider font-medium"
            >
              {t('header.directory')}
            </Link>
            
            <Link 
              to={`/${locale}/compare-brands`}
              className="text-midnight-900 hover:text-gold-700 transition-colors text-sm uppercase tracking-wider font-medium"
            >
              {t('header.compare')}
            </Link>
            
            <Link 
              to={`/${locale}/community`}
              className="text-midnight-900 hover:text-gold-700 transition-colors text-sm uppercase tracking-wider font-medium"
            >
              {t('header.community')}
            </Link>
            
            <Link 
              to={`/${locale}/contact`}
              className="text-midnight-900 hover:text-gold-700 transition-colors text-sm uppercase tracking-wider font-medium"
            >
              {t('header.contact')}
            </Link>
            
            <div className="group relative">
              <button 
                className="text-midnight-900 hover:text-gold-700 transition-colors"
                onClick={() => setSearchOpen(!searchOpen)}
              >
                <Search size={18} />
              </button>
            </div>
          </nav>
        </div>
      </div>
      
      {/* Search Bar */}
      {searchOpen && (
        <div className="absolute top-full left-0 right-0 bg-white border-b border-ivory-200 z-50 py-4 px-6 shadow-sm">
          <div className="container mx-auto">
            <div className="flex items-center">
              <input 
                type="text"
                placeholder={t('common.search')}
                className="w-full border-b border-midnight-200 py-2 px-4 focus:outline-none focus:border-gold-500 text-midnight-900"
                autoFocus
              />
              <button 
                className="ml-2 text-midnight-500 hover:text-midnight-900"
                onClick={() => setSearchOpen(false)}
              >
                <X size={20} />
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Mobile menu */}
      {mobileMenuOpen && (
        <div className="lg:hidden bg-white absolute w-full z-50 shadow-md">
          <div className="px-4 py-5 space-y-3 border-t border-ivory-200">
            <div
              className="block text-midnight-900 hover:text-gold-700 font-medium py-2 cursor-pointer"
              onClick={() => handleNavigate(`/${locale}/`)}
            >
              {t('header.home')}
            </div>
            <div className="space-y-1">
              <div className="block text-midnight-900 font-medium py-2">
                {t('header.editorial')}
              </div>
              <div className="pl-4 space-y-1">
                <div 
                  className="block w-full text-left px-2 py-1.5 text-sm text-midnight-700 hover:text-gold-700 cursor-pointer"
                  onClick={() => handleNavigate(`/${locale}/editorials`)}
                >
                  {t('common.all')} {t('common.editorials')}
                </div>
                <div 
                  className="block w-full text-left px-2 py-1.5 text-sm text-midnight-700 hover:text-gold-700 cursor-pointer"
                  onClick={() => handleNavigate(`/${locale}/editorials/reviews`)}
                >
                  {t('header.reviews')}
                </div>
                <div 
                  className="block w-full text-left px-2 py-1.5 text-sm text-midnight-700 hover:text-gold-700 cursor-pointer"
                  onClick={() => handleNavigate(`/${locale}/editorials/features`)}
                >
                  {t('header.features')}
                </div>
                <div 
                  className="block w-full text-left px-2 py-1.5 text-sm text-midnight-700 hover:text-gold-700 cursor-pointer"
                  onClick={() => handleNavigate(`/${locale}/editorials/news`)}
                >
                  {t('header.news')}
                </div>
                <div 
                  className="block w-full text-left px-2 py-1.5 text-sm text-midnight-700 hover:text-gold-700 cursor-pointer"
                  onClick={() => handleNavigate(`/${locale}/editorials/guides`)}
                >
                  {t('header.guides')}
                </div>
                <div 
                  className="block w-full text-left px-2 py-1.5 text-sm text-midnight-700 hover:text-gold-700 cursor-pointer"
                  onClick={() => handleNavigate(`/${locale}/editorials/comparison`)}
                >
                  {t('header.comparison')}
                </div>
              </div>
            </div>
            <div 
              className="block text-midnight-900 hover:text-gold-700 font-medium py-2 cursor-pointer"
              onClick={() => handleNavigate(`/${locale}/vehicles`)}
            >
              {t('header.directory')}
            </div>
            <div 
              className="block text-midnight-900 hover:text-gold-700 font-medium py-2 cursor-pointer"
              onClick={() => handleNavigate(`/${locale}/compare-brands`)}
            >
              {t('header.compare')}
            </div>
            <div 
              className="block text-midnight-900 hover:text-gold-700 font-medium py-2 cursor-pointer"
              onClick={() => handleNavigate(`/${locale}/community`)}
            >
              {t('header.community')}
            </div>
            <div 
              className="block text-midnight-900 hover:text-gold-700 font-medium py-2 cursor-pointer"
              onClick={() => handleNavigate(`/${locale}/contact`)}
            >
              {t('header.contact')}
            </div>
            <div className="pt-2 pb-4">
              <LanguageSwitch />
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default LuxuryHeader; 