import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { vehicles } from '../data/vehicles';
import { SEOHead } from '../components/SEOHead';
import { useTranslation } from 'react-i18next';
import type { Locale } from '../i18n/seoConfig';
import { BrandComparisonContent } from '../components/BrandComparisonContent';
import { ArrowRight, ChevronRight, Star, Search } from 'lucide-react';

const CompareBrands = () => {
  const { brands } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const locale = i18n.language as Locale;
  const [selectedBrand, setSelectedBrand] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [hoveredBrand, setHoveredBrand] = useState<string | null>(null);

  // Get valid brands list and sort alphabetically
  const validBrands = [...new Set(vehicles.map(v => v.brand))].sort();
  
  // Filter brands based on search term
  const filteredBrands = validBrands.filter(brand => 
    brand.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Get models count for each brand
  const getBrandModelCount = (brand: string) => 
    vehicles.filter(v => v.brand === brand).length;

  // Get price range for each brand
  const getBrandPriceRange = (brand: string) => {
    const brandVehicles = vehicles.filter(v => v.brand === brand);
    const prices = brandVehicles.map(v => v.price);
    return {
      min: Math.min(...prices).toLocaleString(),
      max: Math.max(...prices).toLocaleString()
    };
  };
  
  // Get max range for brand
  const getMaxRange = (brand: string) => {
    const brandVehicles = vehicles.filter(v => v.brand === brand);
    return Math.max(...brandVehicles.map(v => v.range));
  };
  
  // Get min acceleration for brand
  const getBestAcceleration = (brand: string) => {
    const brandVehicles = vehicles.filter(v => v.brand === brand);
    return Math.min(...brandVehicles.map(v => v.acceleration));
  };

  // Handle brand comparison path
  if (brands) {
    const [brand1, brand2] = brands.split('-vs-');
    
    if (!brand1 || !brand2) {
      navigate(`/${locale}/compare-brands`, { replace: true });
      return null;
    }

    const cleanBrand1 = brand1.toLowerCase();
    const cleanBrand2 = brand2.toLowerCase();

    // Validate brands
    if (!validBrands.map(b => b.toLowerCase()).includes(cleanBrand1) || 
        !validBrands.map(b => b.toLowerCase()).includes(cleanBrand2)) {
      navigate(`/${locale}/compare-brands`, { replace: true });
      return null;
    }

    const brand1Vehicles = vehicles.filter(v => v.brand.toLowerCase() === cleanBrand1);
    const brand2Vehicles = vehicles.filter(v => v.brand.toLowerCase() === cleanBrand2);

    return (
      <>
        <SEOHead
          title={`${brand1} vs ${brand2} Comparison`}
          description={`Compare ${brand1} and ${brand2} electric vehicles - specs, prices, and features`}
          canonicalPath={`/${locale}/compare-brands/${cleanBrand1}-vs-${cleanBrand2}`}
          locale={locale}
        />
        <BrandComparisonContent 
          brand1={validBrands.find(b => b.toLowerCase() === cleanBrand1) || brand1}
          brand2={validBrands.find(b => b.toLowerCase() === cleanBrand2) || brand2}
          brand1Vehicles={brand1Vehicles}
          brand2Vehicles={brand2Vehicles}
        />
      </>
    );
  }

  // Brand selection page with interactive grid
  return (
    <>
      <SEOHead
        title="Brand Comparison"
        description="Compare specifications, prices, and features of all available electric vehicle brands"
        canonicalPath={`/${locale}/compare-brands`}
        locale={locale}
      />
      
      {/* Hero Section */}
      <section className="bg-midnight-950 text-white py-16 md:py-20">
        <div className="container mx-auto px-6">
          <div className="max-w-3xl">
            <h1 className="font-serif text-4xl md:text-5xl font-bold mb-6 leading-tight">
              Brand Comparison
            </h1>
            <div className="h-1 w-32 bg-gray-400 mb-8"></div>
            <p className="text-lg md:text-xl text-gray-300 mb-8 max-w-2xl">
              Compare specifications, prices, and features of all available models
            </p>
          </div>
        </div>
      </section>
      
      <div className="bg-white py-16">
        <div className="container mx-auto px-6">
          {/* Search and filter section */}
          <div className="mb-10">
            <div className="relative max-w-md mx-auto mb-8">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
                placeholder="Search brands"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          
          {selectedBrand && (
            <div className="mb-12 p-6 bg-gray-100 text-gray-800 rounded-lg shadow-sm border border-gray-200">
              <div className="flex flex-col md:flex-row items-center justify-between">
                <div className="flex items-center mb-4 md:mb-0">
                  <div className="bg-gray-200 rounded-full p-3 mr-4">
                    <Star className="w-6 h-6 text-gray-700" />
                  </div>
                  <div>
                    <span className="text-gray-600 block">Selected</span>
                    <span className="text-xl font-serif font-bold">{selectedBrand}</span>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <button 
                    onClick={() => setSelectedBrand(null)}
                    className="px-4 py-2 border border-gray-400 hover:border-gray-600 text-gray-700 hover:text-gray-900 transition-colors rounded-md"
                  >
                    Clear Selection
                  </button>
                  <p className="text-gray-600">Select second brand to compare</p>
                </div>
              </div>
            </div>
          )}
          
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {filteredBrands.map(brand => {
              const isHovered = hoveredBrand === brand;
              return (
                <div 
                  key={brand}
                  className={`
                    relative p-6 bg-white border rounded-lg transition-all duration-300
                    ${selectedBrand === brand 
                      ? 'ring-1 ring-gray-400 shadow-md border-gray-400' 
                      : isHovered 
                        ? 'shadow-md border-gray-300' 
                        : 'border-gray-200 hover:shadow-md hover:border-gray-300'
                    }
                  `}
                  onClick={() => {
                    if (selectedBrand && selectedBrand !== brand) {
                      // Navigate to comparison if second brand selected
                      navigate(`/${locale}/compare-brands/${selectedBrand.toLowerCase()}-vs-${brand.toLowerCase()}`);
                    } else {
                      // Select as first brand
                      setSelectedBrand(brand);
                    }
                  }}
                  onMouseEnter={() => setHoveredBrand(brand)}
                  onMouseLeave={() => setHoveredBrand(null)}
                >
                  <div className="flex flex-col">
                    <span className="text-xl font-serif font-bold text-gray-900 mb-2">{brand}</span>
                    <div className="h-0.5 w-16 bg-gray-300 mb-4"></div>
                    
                    <div className="space-y-3 mb-4">
                      <div className="flex items-center text-gray-700">
                        <span className="bg-gray-100 rounded-full w-6 h-6 inline-flex items-center justify-center mr-2">
                          <ChevronRight size={14} />
                        </span>
                        <span>{getBrandModelCount(brand)} models</span>
                      </div>
                      <div className="flex items-center text-gray-700">
                        <span className="bg-gray-100 rounded-full w-6 h-6 inline-flex items-center justify-center mr-2">
                          <ChevronRight size={14} />
                        </span>
                        <span>฿{getBrandPriceRange(brand).min} - ฿{getBrandPriceRange(brand).max}</span>
                      </div>
                      <div className="flex items-center text-gray-700">
                        <span className="bg-gray-100 rounded-full w-6 h-6 inline-flex items-center justify-center mr-2">
                          <ChevronRight size={14} />
                        </span>
                        <span>{getMaxRange(brand)} km max range</span>
                      </div>
                      <div className="flex items-center text-gray-700">
                        <span className="bg-gray-100 rounded-full w-6 h-6 inline-flex items-center justify-center mr-2">
                          <ChevronRight size={14} />
                        </span>
                        <span>{getBestAcceleration(brand)}s acceleration</span>
                      </div>
                    </div>
                    
                    {selectedBrand && selectedBrand !== brand && (
                      <Link
                        to={`/${locale}/compare-brands/${selectedBrand.toLowerCase()}-vs-${brand.toLowerCase()}`}
                        className="mt-4 inline-flex items-center justify-center gap-2 px-4 py-3 bg-gray-800 hover:bg-gray-900 text-white rounded-md font-medium text-sm transition-all w-full"
                      >
                        Compare with {selectedBrand}
                        <ArrowRight size={16} />
                      </Link>
                    )}
                  </div>
                  
                  {!selectedBrand && (
                    <div className={`absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 rounded-lg transition-opacity ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
                      <button className="px-4 py-2 bg-white text-gray-900 rounded-md font-medium">
                        Select Brand
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          
          {filteredBrands.length === 0 && (
            <div className="py-12 text-center">
              <p className="text-xl text-gray-700">No results found</p>
              <button 
                onClick={() => setSearchTerm('')}
                className="mt-4 px-4 py-2 bg-gray-800 text-white rounded-md"
              >
                Clear Search
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CompareBrands;