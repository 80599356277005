export default {
  header: {
    title: "RODFAIFA",
    vehiclesFound: "{{count}} vehicles found",
    quiz: "Quiz",
    compareEvBrands: "Compare EV Brands",
    userExperiences: "User Experiences",
    contactUs: "Contact Us",
    home: "Home",
    editorial: "Editorial",
    directory: "Directory",
    compare: "Compare",
    community: "Community",
    contact: "Contact",
    reviews: "Reviews",
    features: "Features", 
    news: "News",
    guides: "Guides",
    comparison: "Comparison"
  },
  home: {
    title: 'RODFAIFA - Thailand\'s Premier Electric Vehicle Directory',
    subtitle: 'Thailand\'s leading resource for electric vehicle comparisons, reviews, and insights.',
    compare: {
      title: 'Compare EV Models',
      subtitle: 'Find the perfect electric vehicle for your needs',
      action: 'Compare Now'
    },
  },
  filters: {
    search: "Search vehicles...",
    priceRange: "Price Range",
    batteryRange: "Battery Capacity",
    vehicleTypes: "Vehicle Types",
    vehicleMakes: "Brands",
    sortBy: "Sort By",
    priceAsc: "Price: Low to High",
    priceDesc: "Price: High to Low",
    rangeDesc: "Range: Highest",
    accelerationAsc: "Acceleration: Fastest",
    resetAll: "Reset All Filters",
    min: "Min",
    max: "Max",
  },
  sorting: {
    "price-asc": "Price: Low to High",
    "price-desc": "Price: High to Low",
    "range-desc": "Range: High to Low",
    "acceleration-asc": "Acceleration: Fastest First"
  },
  comparison: {
    title: "Compare",
    clearAll: "Clear All"
  },
  vehicle: {
    compare: "Compare",
    added: "Added",
    specifications: "Specifications",
    range: "Range",
    acceleration: "0-100 km/h",
    battery: "Battery",
    topSpeed: "Top Speed",
    year: "Year",
    price: "Price",
    power: "Power",
    torque: "Torque",
    drive: "Drive",
    seating: "Seating",
    cargo: "Cargo Space"
  },
  types: {
    sedan: "Sedan",
    suv: "SUV",
    pickup: "Pickup",
    hatchback: "Hatchback",
    coupe: "Coupe",
    van: "Van"
  },
  contact: {
    formTitle: "Contact Us",
    name: "Name",
    email: "Email",
    message: "Message",
    send: "Send Message",
    sending: "Sending...",
    success: "Thank you for your message. We'll get back to you soon.",
    error: "Failed to send message. Please try again.",
    sendAnother: "Send Another Message",
    address: "Address",
    phone: "Phone",
    subject: "Subject",
    customerSupport: "Customer Support",
    feedback: "Feedback",
    pressInquiry: "Press Inquiry",
    other: "Other"
  },
  experience: {
    title: "User Experiences",
    addExperience: "Share Experience",
    searchExperiences: "Search experiences...",
    filterByMake: "Filter by Make",
    resultsFound: "{{count}} experiences found",
    shareYourThoughts: "Share Your Thoughts",
    selectVehicle: "Select Vehicle",
    positive: "Positive",
    negative: "Negative",
    yourExperience: "Your Experience",
    sharePlaceholder: "Share your experience with this vehicle...",
    yourName: "Your Name",
    submitting: "Submitting...",
    submit: "Submit Experience",
    noResults: "No experiences found matching your criteria",
    submitSuccess: "Experience shared successfully!",
    submitError: "Failed to submit experience. Please try again.",
    enterAdminKey: "Enter admin key",
    confirmDelete: "Are you sure you want to delete this experience?",
    deleteError: "Failed to delete experience",
    delete: "Delete experience"
  },
  seo: {
    comparePage: {
      title: "Compare {{car1}} vs {{car2}} | EV Comparison Thailand",
      description: "Detailed comparison between {{car1}} and {{car2}}. Compare prices, range, features and specifications in Thailand."
    },
    brandPage: {
      title: "{{brand}} Electric Vehicles in Thailand | Prices & Specs",
      description: "Explore {{brand}} electric vehicles available in Thailand. View prices, specifications, and features.",
      heading: "{{brand}} Electric Vehicles",
      subheading: "{{brand}} offers {{count}} electric vehicles in Thailand, starting from {{minPrice}}. Compare all models and find the perfect EV for you."
    }
  },
  categories: {
    SUV: {
      title: "Best Electric SUVs in Thailand 2024 | Compare Prices & Features",
      description: "Compare all electric SUVs available in Thailand. Find the best EV SUV with detailed specifications, prices, and real owner reviews.",
      metaKeywords: "electric SUV, EV SUV Thailand, best electric SUV 2024"
    },
    Sedan: {
      title: "Electric Sedans in Thailand | Compare EV Sedan Models & Prices",
      description: "Complete guide to electric sedans in Thailand. Compare prices, range, and features of all available EV sedans.",
      metaKeywords: "electric sedan, EV sedan Thailand, Tesla Model 3, BYD Seal"
    },
    // ... other categories ...
  },
  brandComparison: {
    title: "Compare Electric Vehicle Brands in Thailand",
    pageTitle: "{{brand1}} vs {{brand2}} EV Comparison Thailand (2024)",
    pageDescription: "Compare {{brand1}} and {{brand2}} electric vehicles in Thailand. Price comparison, specifications, and features of all available models.",
    heading: "{{brand1}} vs {{brand2}} Comparison",
    subheading: "Compare specifications, prices, and features of all available models",
    detailedComparison: "Detailed Comparison",
    keyDifferences: "Key Differences",
    priceComparison: "Price Comparison",
    rangeComparison: "Range Comparison",
    featuresComparison: "Features Comparison",
    faqTitle: "Frequently Asked Questions",
    relatedComparisons: "Related Comparisons",
    availableModels: "Available Models",
    priceRange: "Price Range",
    specifications: "Specifications",
    price: "Price",
    range: "Range",
    batteryCapacity: "Battery Capacity",
    acceleration: "Acceleration",
    compareModels: "Compare with",
    compareNow: "Compare Now →",
    faq: {
      whichBetter: "Which is better: {{brand1}} or {{brand2}}?",
      whichBetterAnswer: "Both {{brand1}} and {{brand2}} offer compelling electric vehicles. {{brand1}} models range from {{price1}}, while {{brand2}} models range from {{price2}}. The choice depends on your specific needs, budget, and preferences.",
      priceRange: "How do {{brand1}} and {{brand2}} prices compare?",
      priceRangeAnswer: "{{brand1}} models are priced from {{price1}}, while {{brand2}} models start from {{price2}}.",
      warranty: "What warranty do {{brand1}} and {{brand2}} offer?",
      warrantyAnswer: "Both manufacturers offer comprehensive warranty packages. Please check with local dealers for specific terms and conditions."
    },
    errors: {
      invalid: {
        title: "Invalid Comparison",
        description: "Please select valid brands to compare"
      },
      notFound: {
        title: "Brands Not Found",
        description: "One or both brands could not be found"
      }
    },
    priceComparisonText: "{{brand1}} models are priced at an average of {{price1}}, while {{brand2}} models average {{price2}}. The price difference between these brands is {{difference}}.",
    rangeComparisonText: "{{brand1}}'s best range is {{range1}} km, compared to {{brand2}}'s {{range2}} km - a difference of {{difference}} km.",
    featuresComparisonText: "{{brand1}} offers {{specs1}}, while {{brand2}} comes with {{specs2}}."
  },
  common: {
    backToHome: "Back to Home",
    loading: "Loading...",
    error: "Error",
    notFound: "Not Found",
    home: "Home",
    vehicles: "Vehicles",
    upTo: "Up to",
    modelsAvailable: "Models Available",
    models: "models",
    backToVehicles: 'Back to Vehicles',
    brands: 'Brands',
    navigation: {
      brands: "Brands",
      allBrands: "All Brands"
    },
    backToTop: 'Back to top',
    editorials: "Editorials",
    editorialNotFound: "Editorial Not Found",
    editorialNotFoundMessage: "Sorry, we couldn't find the editorial you were looking for. It may have been moved or deleted.",
    all: "All",
    description: 'Thailand\'s Trusted Automotive Resource',
    tagline: 'Driving the Future of Automotive Discovery',
  },
  errors: {
    invalidComparison: "Invalid Comparison",
    invalidComparisonDesc: "Please select two different brands to compare.",
    brandsNotFound: "Brands Not Found",
    brandsNotFoundDesc: "We couldn't find the brands you're looking for. Please try different brands.",
    invalidBrand: 'Invalid Brand',
    brandNotSpecified: 'No brand was specified. Please select a brand from our vehicle list.',
    brandNotFound: 'Brand Not Found',
    brandNotFoundDescription: 'Sorry, we couldn\'t find any vehicles for the brand "{{brand}}". Please check our available brands.',
  },
  // **New Quiz Translations**
  quiz: {
    title: "Find Your Ideal EV Car",
    consentText: "We collect your answers to provide personalized recommendations. Do you agree?",
    agree: "I Agree",
    questionBudget: "What is your budget (in Baht)?",
    questionRange: "What minimum range do you require (in km)?",
    questionUsage: "What is your primary usage?",
    questionSeating: "How many seats do you need?",
    next: "Next",
    recommendations: "Recommended EV Cars for You",
    noRecommendations: "No vehicles match your criteria. Please adjust your preferences.",
    step: "Step {{current}} of {{total}}",
    select: "Select an option",
    usageOptions: {
      city: "City Driving",
      highway: "Highway Driving",
      mixed: "Mixed City and Highway"
    },
    seatingOptions: {
      '2': "2 seats",
      '4': "4 seats",
      '5': "5 seats",
      '7': "7 seats"
    },
    showResults: "Show Recommendations",
    startOver: "Start Over",
    questionType: "What type of vehicle are you looking for?"
  },
  pages: {
    home: {
      title: 'RODFAIFA - Compare Electric Vehicles in Thailand | EV Price & Specs Guide 2024'
    },
    comparison: {
      title: 'Compare {{brand1}} vs {{brand2}} - EV Comparison Thailand'
    },
    experience: {
      title: 'EV Owner Experiences - Real User Reviews'
    },
    contact: {
      title: 'Contact Us | RODFAIFA',
      description: "Get in touch with RODFAIFA. We're here to help with your electric vehicle questions.",
      subtitle: "Have a question about electric vehicles in Thailand? We're here to help!"
    },
    quiz: {
      title: 'Find Your Perfect EV - Quick Quiz'
    },
    electricCars: {
      title: "Electric Cars in Thailand 2024 - Complete Guide",
      subtitle: "Discover the latest electric cars available in Thailand, with detailed comparisons, prices, and specifications. From affordable city cars to premium luxury EVs, find the perfect electric vehicle for your needs.",
      stats: {
        availableModels: "Available Models",
        startingPrice: "Starting Price",
        maxRange: "Max Range",
        brandsAvailable: "Brands Available"
      },
      brands: {
        title: "EV Brands in Thailand",
        description: "Thailand's electric vehicle market has grown significantly, with {{count}} major brands now offering EVs. Compare popular brands like Tesla, BYD, and more to find your ideal electric car.",
        modelsAvailable: "{{count}} models available"
      },
      priceRanges: {
        title: "EV Price Ranges in Thailand",
        budget: {
          title: "Budget EVs (Under ฿1M)",
          description: "Affordable electric vehicles starting under 1 million baht. Perfect for city driving and first-time EV buyers. {{count}} models available in this range."
        },
        midRange: {
          title: "Mid-Range EVs (฿1M - ฿2M)",
          description: "Mid-range electric vehicles priced between 1 million and 2 million baht. Ideal for families and long-distance travel. {{count}} models available in this range."
        },
        premium: {
          title: "Premium EVs (Over ฿2M)",
          description: "Luxury electric vehicles priced over 2 million baht. Perfect for high-end luxury and performance. {{count}} models available in this range."
        }
      }
    },
    brands: {
      title: "Electric Vehicle Brands in Thailand | Compare All EV Makes",
      description: "Compare all electric vehicle brands available in Thailand. View prices, specifications, and features across different manufacturers.",
      heading: "Electric Vehicle Brands",
      modelCount: "{{count}} models",
      priceRange: "Price range: {{min}} - {{max}}"
    },
    vehicles: {
      title: "Luxury Electric Vehicles | RodFaiFa",
      description: "Explore our curated selection of premium electric vehicles available in Thailand.",
      allVehicles: "Electric Vehicles",
      foundCount: "{{count}} vehicles found",
    },
    notFound: {
      title: "Page Not Found | RODFAIFA",
      description: "The page you're looking for doesn't exist or has been moved.",
      message: "Sorry, we couldn't find the page you're looking for."
    },
    vehicleType: {
      title: "{{type}} Electric Cars in Thailand",
      description: "Compare all {{type}} electric vehicles available in Thailand. Find detailed specifications, prices, and features."
    }
  },
  footer: {
    aboutShort: "RODFAIFA is the premier destination for electric vehicle enthusiasts in Thailand and ASEAN, providing authoritative content, community engagement, and curated experiences.",
    editorial: "Editorial",
    reviews: "Reviews",
    features: "Features",
    news: "News",
    guides: "Guides",
    directory: "Directory",
    allVehicles: "All Vehicles",
    sedans: "Sedans",
    suvs: "SUVs",
    compareBrands: "Compare Brands",
    community: "Community",
    forum: "Forum",
    events: "Events",
    contact: "Contact Us",
    legal: "Legal",
    privacy: "Privacy Policy",
    terms: "Terms of Use",
    rightsReserved: "All Rights Reserved",
    madeWith: "Made with",
    inThailand: "in Thailand",
    tagline: 'Thailand\'s Premier Electric Vehicle Directory',
  },
  luxuryHome: {
    hero: {
      title: "The Definitive Voice for Electric Vehicles in ASEAN",
      subtitle: "Discover in-depth reviews, insightful features, and a vibrant community dedicated to the evolving world of electric mobility in Thailand and beyond.",
      primaryButton: "Explore Articles",
      secondaryButton: "Browse Directory"
    },
    featuredArticle: {
      heading: "Featured Article"
    },
    latestArticles: {
      heading: "Latest Articles"
    },
    featuredVehicles: {
      heading: "Featured Vehicles"
    },
    newsletter: {
      heading: "Join Our Community",
      subheading: "Subscribe to receive the latest news, exclusive insights, and event invitations.",
      placeholder: "Your email address",
      button: "Subscribe"
    },
    viewAll: "View All",
    readMore: "Read More",
    categories: {
      reviews: "Review",
      features: "Feature",
      news: "News",
      guides: "Guide",
      comparison: "Comparison"
    }
  },
  editorial: {
    readTime: "{{minutes}} min read",
    shareEditorial: "Share this editorial",
    share: "Share",
    continueReading: "Read More",
    aboutAuthor: "About the Author",
    contributor: "RODFAIFA Contributor",
    authorBio: "A passionate automotive journalist specializing in electric vehicles. Covering the EV revolution across Thailand and ASEAN since 2019.",
    relatedEditorials: "Related Editorials",
    readMore: "Read More"
  },
  editorials: {
    title: "EV Editorials - Latest News, Reviews & Guides | RODFAIFA",
    description: "Explore our editorial content covering the latest electric vehicle trends, reviews, and insights for luxury EV enthusiasts in Thailand.",
    exploreLatest: "Explore our latest editorials covering everything from in-depth vehicle reviews to guides on EV ownership in Thailand.",
    featuredComparisons: "Featured Comparisons",
    latestEditorials: "Latest Editorials",
    viewAllEditorials: "Browse All Editorials",
    noEditorialsFound: "No editorials found in this category",
    featuredEditorials: "Featured Editorials",
    categories: {
      reviews: {
        title: "Electric Vehicle Reviews | RODFAIFA",
        description: "In-depth electric vehicle reviews with real-world performance testing and owner perspectives."
      },
      features: {
        title: "EV Features & Analysis | RODFAIFA",
        description: "Deep dives and analysis of electric vehicle technology, trends, and the evolving EV landscape."
      },
      news: {
        title: "Electric Vehicle News | RODFAIFA",
        description: "Breaking news and updates on electric vehicles, charging networks, and EV policy in Thailand and beyond."
      },
      guides: {
        title: "EV Guides & Resources | RODFAIFA",
        description: "Practical guides and resources for current and prospective electric vehicle owners in Thailand."
      },
      comparison: {
        title: "EV Comparison Tests | RODFAIFA",
        description: "Head-to-head comparison tests between competing electric vehicles to help you make informed decisions."
      }
    }
  },
  community: {
    pageTitle: "RODFAIFA Community - EV Enthusiasts Forum",
    pageDescription: "Join the premier community for electric vehicle enthusiasts in Thailand and ASEAN. Discuss, share experiences, and connect with fellow EV owners.",
    hero: {
      title: "Join the RODFAIFA Community",
      subtitle: "Connect with fellow EV enthusiasts, share experiences, ask questions, and stay updated with the latest discussions.",
      joinButton: "Join Community",
      loginButton: "Log In"
    },
    search: "Search topics...",
    newTopic: "New Topic",
    categories: "Categories",
    recentTopics: "Recent Topics",
    topics: "topics",
    posts: "posts",
    replies: "replies",
    views: "views",
    by: "by",
    stats: {
      title: "Community Stats",
      members: "Members",
      topics: "Topics",
      posts: "Posts",
      online: "Online Now"
    },
    topContributors: "Top Contributors",
    upcomingEvents: "Upcoming Events",
    viewAllEvents: "View all events"
  },
  vehicles: {
    details: "Details",
    compare: "Compare",
    compared: "Added",
    addToFavorites: "Add to Favorites",
    removeFromFavorites: "Remove from Favorites",
  },
};
