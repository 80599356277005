import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SEOHead } from './SEOHead';
import { ChevronRight, Calendar, User, Clock, Share2, Facebook, Twitter, Linkedin, Bookmark } from 'lucide-react';
import { vehicles } from '../data/vehicles';
import { getAuthorBio, getAuthorInfo } from '../utils/authorLoader';

interface EditorialLayoutProps {
  title: string;
  description: string;
  category: string;
  canonicalPath: string;
  authorId: string;
  author: string;
  authorAvatar?: string;
  publishDate: string;
  readTime: number;
  heroImage: string;
  children: React.ReactNode;
  relatedArticles?: Array<{
    id: string;
    title: string;
    category: string;
    image: string;
  }>;
}

const EditorialLayout: React.FC<EditorialLayoutProps> = ({
  title,
  description,
  category,
  canonicalPath,
  authorId,
  author,
  authorAvatar,
  publishDate,
  readTime,
  heroImage,
  children,
  relatedArticles = []
}) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const [readingProgress, setReadingProgress] = useState(0);

  // Get author bio based on current language
  const authorBio = authorId ? getAuthorBio(authorId, locale) : t('editorial.authorBio');
  // Get author role information
  const authorInfo = authorId ? getAuthorInfo(authorId, author) : { name: author, role: '' };

  // Track reading progress
  useEffect(() => {
    const updateReadingProgress = () => {
      const currentProgress = window.scrollY;
      const scrollHeight = document.body.scrollHeight - window.innerHeight;
      if (scrollHeight) {
        setReadingProgress(Number((currentProgress / scrollHeight).toFixed(2)) * 100);
      }
    };

    window.addEventListener('scroll', updateReadingProgress);
    return () => window.removeEventListener('scroll', updateReadingProgress);
  }, []);

  return (
    <>
      <SEOHead
        title={title}
        description={description}
        canonicalPath={canonicalPath}
        locale={locale}
      />
      
      {/* Reading Progress Bar */}
      <div className="fixed top-0 left-0 w-full h-1 z-50 bg-transparent">
        <div 
          className="h-full bg-gold-500 transition-all duration-100 ease-out"
          style={{ width: `${readingProgress}%` }}
        />
      </div>
      
      <main className="min-h-screen bg-ivory-50">
        {/* Editorial Header */}
        <div className="bg-midnight-950 py-12 md:py-20">
          <div className="container mx-auto px-6">
            <nav className="flex mb-8" aria-label="Breadcrumb">
              <ol className="flex items-center space-x-1">
                <li>
                  <Link to={`/${locale}/`} className="text-ivory-300 hover:text-ivory-100 transition-colors">
                    {t('common.home')}
                  </Link>
                </li>
                <li className="flex items-center">
                  <ChevronRight className="w-4 h-4 text-ivory-500 mx-1" />
                  <Link to={`/${locale}/editorials`} className="text-ivory-300 hover:text-ivory-100 transition-colors">
                    {t('common.editorials')}
                  </Link>
                </li>
                <li className="flex items-center">
                  <ChevronRight className="w-4 h-4 text-ivory-500 mx-1" />
                  <Link to={`/${locale}/editorials/${category}`} className="text-ivory-300 hover:text-ivory-100 transition-colors">
                    {t(`luxuryHome.categories.${category}`)}
                  </Link>
                </li>
              </ol>
            </nav>
            
            <div className="max-w-4xl mx-auto">
              <span className="inline-block mb-4 text-gold-400 font-medium uppercase tracking-wider text-sm">
                {t(`luxuryHome.categories.${category}`)}
              </span>
              
              <h1 className="font-serif text-3xl md:text-4xl lg:text-5xl font-bold text-ivory-50 mb-8 leading-tight">
                {title}
              </h1>
              
              <p className="text-ivory-300 text-lg mb-8 leading-relaxed">
                {description}
              </p>
              
              <div className="flex flex-wrap items-center gap-x-6 gap-y-3 text-sm text-ivory-400 border-t border-midnight-800 pt-6">
                <div className="flex items-center gap-2">
                  <User size={16} />
                  <span>{author}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Calendar size={16} />
                  <span>{publishDate}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Clock size={16} />
                  <span>{t('editorial.readTime', { minutes: readTime })}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Editorial Content */}
        <div className="container mx-auto px-6 pt-8 pb-12 md:pb-20">
          <div className="flex flex-col lg:flex-row gap-8">
            {/* Side Share Buttons (desktop) */}
            <div className="hidden lg:flex flex-col items-center sticky top-32 h-fit self-start">
              <div className="flex flex-col gap-4">
                <button className="p-2 bg-midnight-100 hover:bg-midnight-200 transition-colors text-midnight-700" aria-label="Share on Facebook">
                  <Facebook size={20} />
                </button>
                <button className="p-2 bg-midnight-100 hover:bg-midnight-200 transition-colors text-midnight-700" aria-label="Share on Twitter">
                  <Twitter size={20} />
                </button>
                <button className="p-2 bg-midnight-100 hover:bg-midnight-200 transition-colors text-midnight-700" aria-label="Share on LinkedIn">
                  <Linkedin size={20} />
                </button>
                <button className="p-2 bg-midnight-100 hover:bg-midnight-200 transition-colors text-midnight-700" aria-label="Bookmark editorial">
                  <Bookmark size={20} />
                </button>
              </div>
            </div>
            
            {/* Main Content */}
            <div className="lg:w-2/3 mx-auto max-w-3xl">
              <article className="editorial-content">
                {children}
              </article>
              
              {/* Author Bio */}
              <div className="mt-12 pt-8 border-t border-ivory-200 bg-white p-8">
                <h3 className="font-serif text-xl font-bold text-midnight-950 mb-4">
                  {t('editorial.aboutAuthor')}
                </h3>
                <div className="flex items-start gap-6">
                  <div className="w-20 h-20 overflow-hidden rounded-full bg-midnight-100 flex-shrink-0">
                    {authorAvatar ? (
                      <img 
                        src={authorAvatar} 
                        alt={author} 
                        className="w-full h-full object-cover"
                        style={{ aspectRatio: '1/1' }}
                      />
                    ) : (
                      <div className="w-full h-full bg-midnight-200 flex items-center justify-center text-midnight-700">
                        {author.charAt(0)}
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="font-serif font-bold text-lg text-midnight-900 mb-2">
                      {authorInfo.name}
                      {authorInfo.role && (
                        <span className="font-normal text-sm text-midnight-600 ml-2">
                          {authorInfo.role}
                        </span>
                      )}
                    </div>
                    <p className="text-midnight-700 leading-relaxed">
                      {authorBio}
                    </p>
                  </div>
                </div>
              </div>
              
              {/* Social Share (mobile) */}
              <div className="mt-8 lg:hidden">
                <div className="flex items-center justify-center gap-4">
                  <div className="flex gap-3">
                    <button className="p-2 bg-midnight-100 hover:bg-midnight-200 transition-colors text-midnight-700" aria-label="Share on Facebook">
                      <Facebook size={18} />
                    </button>
                    <button className="p-2 bg-midnight-100 hover:bg-midnight-200 transition-colors text-midnight-700" aria-label="Share on Twitter">
                      <Twitter size={18} />
                    </button>
                    <button className="p-2 bg-midnight-100 hover:bg-midnight-200 transition-colors text-midnight-700" aria-label="Share on LinkedIn">
                      <Linkedin size={18} />
                    </button>
                    <button className="p-2 bg-midnight-100 hover:bg-midnight-200 transition-colors text-midnight-700" aria-label="Bookmark editorial">
                      <Bookmark size={18} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Empty space for symmetry */}
            <div className="hidden lg:block lg:w-8"></div>
          </div>
          
          {/* Related Articles */}
          {relatedArticles.length > 0 && (
            <div className="mt-16 pt-12 border-t border-ivory-200">
              <h2 className="font-serif text-3xl font-bold text-midnight-950 mb-8 text-center">
                {t('editorial.relatedArticles')}
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {relatedArticles.map(article => (
                  <Link 
                    key={article.id} 
                    to={`/${locale}/editorials/${article.category}/${article.id}`}
                    className="group"
                  >
                    <div className="bg-white overflow-hidden shadow-sm hover:shadow-md transition-all duration-300">
                      <div className="h-48 overflow-hidden relative">
                        <img 
                          src={article.image} 
                          alt={article.title}
                          className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500" 
                        />
                      </div>
                      <div className="p-6">
                        <h3 className="font-serif text-lg font-bold text-midnight-900 group-hover:text-gold-700 transition-colors mb-2 leading-tight">
                          {article.title}
                        </h3>
                        <div className="text-sm text-midnight-600">
                          {t('editorial.continueReading')} →
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default EditorialLayout; 