import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SEOHead } from '../components/SEOHead';
import { vehicles } from '../data/vehicles';
import { ArrowRight, ArrowUpRight } from 'lucide-react';
import editorialData from '../data/editorials.json';
import { Editorial, EditorialDisplay } from '../types/editorial';

// Type assertion for the imported JSON data
const typedEditorialData = editorialData as Editorial[];

// Create default article in case none is found
const defaultArticle: Editorial = {
  id: 'zeekr-vs-byd',
  title: "The New Kings of Asia: Why ZEEKR's Design Excellence Challenges BYD's Market Dominance",
  description: "A deep dive into how ZEEKR's premium approach is challenging BYD's volume strategy across Asian markets",
  category: 'comparison',
  featured: true,
  author: 'Harold Choi',
  authorId: 'harold-choi',
  publishDate: 'March 26, 2023',
  readTime: 14,
  image: '/images/zeekr-vs-byd.jpg'
};

// Get featured and latest articles from JSON data
const featuredArticle = typedEditorialData.find(article => article.featured === true) || defaultArticle;

// Helper function to parse dates
const parseDate = (dateStr: string): Date => {
  // Handle dates in format "Month Day, Year"
  return new Date(dateStr);
};

// Sort articles by date (newest first) before slicing
const sortedArticles = [...typedEditorialData].sort((a, b) => 
  parseDate(b.publishDate).getTime() - parseDate(a.publishDate).getTime()
);

// For now, we only have one article, but this structure allows for easy expansion
const latestArticles: EditorialDisplay[] = sortedArticles.slice(0, 3).map(article => ({
  id: article.id,
  title: article.title,
  excerpt: article.description, // Map description to excerpt
  category: article.category,
  image: article.image,
  author: article.author,
  date: article.publishDate, // Map publishDate to date
  featured: article.featured
}));

// Add this above the featuredVehicles declaration
// You can easily modify this array to change which vehicles are featured
const featuredVehicleIds = ['seal_performance', 'atto3_extended_new', 'dolphin-standard-new', 'm6_extended'];

// Replace the current featuredVehicles with this implementation
const featuredVehicles = featuredVehicleIds
  .map(id => vehicles.find(vehicle => vehicle.id === id))
  .filter(vehicle => vehicle !== undefined) as typeof vehicles;

// Fallback to first 4 if we don't have enough featured vehicles
if (featuredVehicles.length < 4) {
  const remainingCount = 4 - featuredVehicles.length;
  const nonFeaturedVehicles = vehicles
    .filter(vehicle => !featuredVehicleIds.includes(vehicle.id))
    .slice(0, remainingCount);
  
  featuredVehicles.push(...nonFeaturedVehicles);
}

// Create a display version of the featured article
const featuredArticleDisplay: EditorialDisplay = {
  id: featuredArticle.id,
  title: featuredArticle.title,
  excerpt: featuredArticle.description,
  category: featuredArticle.category,
  image: featuredArticle.image,
  author: featuredArticle.author,
  date: featuredArticle.publishDate
};

const LuxuryHomePage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const canonicalPath = locale === 'th' ? '/th/' : '/en/';
  
  return (
    <>
      <SEOHead
        title={t('home.title')}
        description={t('home.subtitle')}
        canonicalPath={canonicalPath}
        locale={locale}
      />
      
      <main className="min-h-screen bg-ivory-50">
        {/* Hero Section */}
        <section className="bg-midnight-950 text-ivory-50 py-24 md:py-32">
          <div className="container mx-auto px-6">
            <div className="max-w-4xl mx-auto text-center">
              <h1 className="font-serif text-4xl md:text-5xl lg:text-6xl font-bold mb-6 leading-tight">
                {t('luxuryHome.hero.title')}
              </h1>
              <p className="text-lg md:text-xl text-ivory-200 mb-8 max-w-3xl mx-auto">
                {t('luxuryHome.hero.subtitle')}
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Link
                  to={`/${locale}/editorials`}
                  className="px-8 py-3 bg-gold-500 hover:bg-gold-600 text-midnight-950 font-medium inline-flex items-center justify-center transition-colors"
                >
                  {t('luxuryHome.hero.primaryButton')}
                </Link>
                <Link
                  to={`/${locale}/vehicles`}
                  className="px-8 py-3 bg-transparent border border-ivory-300 text-ivory-100 hover:border-gold-400 hover:text-gold-300 font-medium inline-flex items-center justify-center transition-colors"
                >
                  {t('luxuryHome.hero.secondaryButton')}
                </Link>
              </div>
            </div>
          </div>
        </section>
        
        {/* Featured Article */}
        <section className="py-16 md:py-24">
          <div className="container mx-auto px-6">
            <div className="flex items-center justify-between mb-12">
              <h2 className="font-serif text-3xl font-bold text-midnight-950">
                {t('luxuryHome.featuredArticle.heading')}
              </h2>
              <Link 
                to={`/${locale}/editorials`}
                className="text-midnight-800 hover:text-gold-700 font-medium inline-flex items-center gap-1"
              >
                {t('luxuryHome.viewAll')} 
                <ArrowRight size={16} />
              </Link>
            </div>
            
            <div className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-lg transition-shadow">
              <div className="md:flex">
                <div className="md:w-1/2 h-64 md:h-auto relative">
                  <img 
                    src={featuredArticleDisplay.image} 
                    alt={featuredArticleDisplay.title}
                    className="w-full h-full object-cover" 
                  />
                  <div className="absolute top-4 left-4 bg-gold-500 text-midnight-950 px-3 py-1 text-sm font-medium">
                    {t(`luxuryHome.categories.${featuredArticleDisplay.category}`)}
                  </div>
                </div>
                <div className="md:w-1/2 p-6 md:p-8 flex flex-col justify-between">
                  <div>
                    <h3 className="font-serif text-2xl md:text-3xl font-bold text-midnight-950 mb-4">
                      {featuredArticleDisplay.title}
                    </h3>
                    <p className="text-midnight-700 mb-6">
                      {featuredArticleDisplay.excerpt}
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <span className="text-sm text-midnight-600">
                        {featuredArticleDisplay.author}
                      </span>
                      <span className="text-sm text-midnight-500">•</span>
                      <span className="text-sm text-midnight-500">
                        {featuredArticleDisplay.date}
                      </span>
                    </div>
                    <Link 
                      to={`/${locale}/editorials/${featuredArticleDisplay.category}/${featuredArticleDisplay.id}`}
                      className="text-gold-700 hover:text-gold-800 inline-flex items-center gap-1 font-medium"
                    >
                      {t('luxuryHome.readMore')}
                      <ArrowUpRight size={16} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/* Latest Articles */}
        <section className="py-16 bg-ivory-100">
          <div className="container mx-auto px-6">
            <div className="flex items-center justify-between mb-12">
              <h2 className="font-serif text-3xl font-bold text-midnight-950">
                {t('luxuryHome.latestArticles.heading')}
              </h2>
              <Link 
                to={`/${locale}/editorials`}
                className="text-midnight-800 hover:text-gold-700 font-medium inline-flex items-center gap-1"
              >
                {t('luxuryHome.viewAll')} 
                <ArrowRight size={16} />
              </Link>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {latestArticles.map((article) => (
                <article key={article.id} className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-lg transition-shadow flex flex-col">
                  <div className="h-48 relative">
                    <img 
                      src={article.image} 
                      alt={article.title}
                      className="w-full h-full object-cover" 
                    />
                    <div className="absolute top-4 left-4 bg-gold-500 text-midnight-950 px-3 py-1 text-sm font-medium">
                      {t(`luxuryHome.categories.${article.category}`)}
                    </div>
                  </div>
                  <div className="p-6 flex flex-col flex-grow">
                    <h3 className="font-serif text-xl font-bold text-midnight-950 mb-3">
                      {article.title}
                    </h3>
                    <p className="text-midnight-700 mb-6 flex-grow">
                      {article.excerpt}
                    </p>
                    <div className="flex items-center justify-between mt-auto">
                      <div className="flex flex-col">
                        <span className="text-sm text-midnight-600">
                          {article.author}
                        </span>
                        <span className="text-sm text-midnight-500">
                          {article.date}
                        </span>
                      </div>
                      <Link 
                        to={`/${locale}/editorials/${article.category}/${article.id}`}
                        className="text-gold-700 hover:text-gold-800 inline-flex items-center gap-1 font-medium"
                      >
                        <ArrowUpRight size={16} />
                      </Link>
                    </div>
                  </div>
                </article>
              ))}
            </div>
          </div>
        </section>
        
        {/* Featured Vehicles */}
        <section className="py-16 md:py-24">
          <div className="container mx-auto px-6">
            <div className="flex items-center justify-between mb-12">
              <h2 className="font-serif text-3xl font-bold text-midnight-950">
                {t('luxuryHome.featuredVehicles.heading')}
              </h2>
              <Link 
                to={`/${locale}/vehicles`}
                className="text-midnight-800 hover:text-gold-700 font-medium inline-flex items-center gap-1"
              >
                {t('luxuryHome.viewAll')} 
                <ArrowRight size={16} />
              </Link>
            </div>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
              {featuredVehicles.map((vehicle) => (
                <div key={vehicle.id} className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-lg transition-all group">
                  <div className="h-40 overflow-hidden">
                    <img 
                      src={vehicle.image || '/vehicle-placeholder.jpg'} 
                      alt={vehicle.name}
                      className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300" 
                    />
                  </div>
                  <div className="p-5">
                    <div className="text-xs font-medium text-midnight-500 mb-1">
                      {vehicle.brand}
                    </div>
                    <h3 className="font-medium text-lg text-midnight-950 mb-2">
                      {vehicle.name}
                    </h3>
                    <div className="flex justify-between items-center">
                      <div className="text-gold-700 font-medium">
                        ฿{vehicle.price.toLocaleString()}
                      </div>
                      <Link 
                        to={`/${locale}/vehicles/${vehicle.id}`}
                        className="text-midnight-700 hover:text-gold-700"
                      >
                        <ArrowUpRight size={16} />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            <div className="mt-8 text-center">
              <p className="text-sm text-midnight-600">
                {t('luxuryHome.featuredVehicles.brandCta', 'Want to feature your vehicle here?')} {' Contact'} {'  '}
                <a href="mailto:marketing@rodfaifa.com" className="text-gold-700 hover:text-gold-800 transition-colors">
                  marketing@rodfaifa.com
                </a>
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default LuxuryHomePage; 