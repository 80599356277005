import React from 'react';
import { useTranslation } from 'react-i18next';
import { EVehicle } from '../data/vehicles';
import { formatPrice } from '../utils/currency';

interface ComparisonTableProps {
  brand1Vehicles: EVehicle[];
  brand2Vehicles: EVehicle[];
}

export const ComparisonTable: React.FC<ComparisonTableProps> = React.memo(({ 
  brand1Vehicles, 
  brand2Vehicles 
}) => {
  const { t } = useTranslation();
  
  const allVehicles = [...brand1Vehicles, ...brand2Vehicles];
  
  // Helper function to determine which value is better
  const getBetterValue = (
    key: 'price' | 'range' | 'acceleration' | 'batteryCapacity' | 'topSpeed',
    vehicles: EVehicle[],
    lowerIsBetter = false
  ) => {
    const values = vehicles.map(v => v[key]);
    return lowerIsBetter ? Math.min(...values) : Math.max(...values);
  };
  
  // Helper function to check if a value is the best in its category
  const isBestValue = (
    vehicle: EVehicle,
    key: 'price' | 'range' | 'acceleration' | 'batteryCapacity' | 'topSpeed', 
    lowerIsBetter = false
  ) => {
    const value = vehicle[key];
    const brand1Best = getBetterValue(key, brand1Vehicles, lowerIsBetter);
    const brand2Best = getBetterValue(key, brand2Vehicles, lowerIsBetter);
    const allBest = lowerIsBetter ? Math.min(brand1Best, brand2Best) : Math.max(brand1Best, brand2Best);
    
    return value === allBest;
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full min-w-[800px] border-collapse">
        <thead>
          <tr className="bg-gray-100">
            <th className="text-left p-3 border-b-2 border-gray-200 font-serif font-bold text-gray-900">Specifications</th>
            {brand1Vehicles.map(v => (
              <th key={v.id} className="text-left p-3 border-b-2 border-gray-200 font-serif bg-gray-50 font-bold text-gray-900">
                {v.name}
              </th>
            ))}
            {brand2Vehicles.map(v => (
              <th key={v.id} className="text-left p-3 border-b-2 border-gray-200 font-serif bg-gray-50 font-bold text-gray-900">
                {v.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-4 border-b border-gray-200 bg-gray-50 font-medium">Price</td>
            {allVehicles.map(v => (
              <td key={v.id} className={`p-4 border-b border-gray-200 ${isBestValue(v, 'price', true) ? 'font-medium' : ''}`}>
                {formatPrice(v.price)}
                {isBestValue(v, 'price', true) && <span className="text-xs text-gray-500 ml-1">*</span>}
              </td>
            ))}
          </tr>
          <tr>
            <td className="p-4 border-b border-gray-200 bg-gray-50 font-medium">Range</td>
            {allVehicles.map(v => (
              <td key={v.id} className={`p-4 border-b border-gray-200 ${isBestValue(v, 'range') ? 'font-medium' : ''}`}>
                {v.range} km
                {isBestValue(v, 'range') && <span className="text-xs text-gray-500 ml-1">*</span>}
              </td>
            ))}
          </tr>
          <tr>
            <td className="p-4 border-b border-gray-200 bg-gray-50 font-medium">Battery Capacity</td>
            {allVehicles.map(v => (
              <td key={v.id} className={`p-4 border-b border-gray-200 ${isBestValue(v, 'batteryCapacity') ? 'font-medium' : ''}`}>
                {v.batteryCapacity} kWh
                {isBestValue(v, 'batteryCapacity') && <span className="text-xs text-gray-500 ml-1">*</span>}
              </td>
            ))}
          </tr>
          <tr>
            <td className="p-4 border-b border-gray-200 bg-gray-50 font-medium">Acceleration (0-100 km/h)</td>
            {allVehicles.map(v => (
              <td key={v.id} className={`p-4 border-b border-gray-200 ${isBestValue(v, 'acceleration', true) ? 'font-medium' : ''}`}>
                {v.acceleration}s
                {isBestValue(v, 'acceleration', true) && <span className="text-xs text-gray-500 ml-1">*</span>}
              </td>
            ))}
          </tr>
          <tr>
            <td className="p-4 border-b border-gray-200 bg-gray-50 font-medium">Top Speed</td>
            {allVehicles.map(v => (
              <td key={v.id} className={`p-4 border-b border-gray-200 ${isBestValue(v, 'topSpeed') ? 'font-medium' : ''}`}>
                {v.topSpeed} km/h
                {isBestValue(v, 'topSpeed') && <span className="text-xs text-gray-500 ml-1">*</span>}
              </td>
            ))}
          </tr>
          <tr>
            <td className="p-4 border-b border-gray-200 bg-gray-50 font-medium">Body Type</td>
            {allVehicles.map(v => (
              <td key={v.id} className="p-4 border-b border-gray-200">
                {v.type}
              </td>
            ))}
          </tr>
          <tr>
            <td className="p-4 border-b border-gray-200 bg-gray-50 font-medium">Power Output</td>
            {allVehicles.map(v => (
              <td key={v.id} className="p-4 border-b border-gray-200">
                {v.specs.power}
              </td>
            ))}
          </tr>
          <tr>
            <td className="p-4 border-b border-gray-200 bg-gray-50 font-medium">Drive System</td>
            {allVehicles.map(v => (
              <td key={v.id} className="p-4 border-b border-gray-200">
                {v.specs.drive}
              </td>
            ))}
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={allVehicles.length + 1} className="p-3 text-xs text-gray-600">
              * Best value in this category
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
});
